import { useWebStoreContext } from "contexts";
import { IShopPurchaseOffer, IShopPurchaseOfferSingleItem } from "contexts/WebStoreProvider/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { IOffer } from "pages/StoreFront/lib/SingleItems";
import { useEffect, useState } from "react";
import { useGetOffersQuery } from "redux/apis";
import { onClosePopUp } from "redux/slices";
import { selectShopSectionsState } from "redux/slices/shop-sections";
import { Slide1, Slide2, Slide3, Slide4, Slide5 } from "./lib";

export const Tutorial = () => {
	const dispatch = useAppDispatch();

	const { authToken } = useWebStoreContext();
	const { shopSections } = useAppSelector(selectShopSectionsState);
	const { data: shop } = useGetOffersQuery(
		{
			offerType: "singleItem",
			isAuthenticated: !!authToken?.value
		}
	);

	const [slideNumber, setSlideNumber] = useState(1);
	const [sectionOffers, setSectionOffers] = useState<Array<IShopPurchaseOffer[] & IShopPurchaseOfferSingleItem[]>>();

	useEffect(() => {
		const filteredSections = shopSections?.mainShopSections.filter((data) =>
			shop.offers?.find(
				(of: IOffer) =>
					of.sectionName === data.name && of.sectionName !== "limited_deals"
			)
		);
		const sectionOffers = filteredSections.map((data) => {
			return shop?.offers.filter(
				(of: IOffer) => of.sectionName === data.name
			);
		});
		setSectionOffers(sectionOffers);
	}, [shopSections, shop]);

	useEffect(() => {
		if (slideNumber === 6) {
			dispatch(onClosePopUp());
		}
	}, [slideNumber]);

	useEffect(() => {
		if (location.pathname !== "/shop") {
			dispatch(onClosePopUp());
		}
	}, [location]);

	return (
		<>
			{slideNumber === 1 && (<Slide1 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 2 && (<Slide2 onNext={() => setSlideNumber(slideNumber + 1)} sectionOffers={sectionOffers} />)}
			{slideNumber === 3 && (<Slide3 onNext={() => setSlideNumber(slideNumber + 1)} sectionOffers={sectionOffers} />)}
			{slideNumber === 4 && (<Slide4 onNext={() => setSlideNumber(slideNumber + 1)} />)}
			{slideNumber === 5 && (<Slide5 onNext={() => setSlideNumber(slideNumber + 1)} />)}
		</>
	)
};
