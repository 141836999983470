import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import tiki from "assets/tutorial/tiki-2.png";
import Button from "components/Buttons/ThemeButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { IShopPurchaseOffer, IShopPurchaseOfferSingleItem } from "contexts/WebStoreProvider/types";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { onClosePopUp } from "redux/slices";
import { Arrow, Background2, Text, Tiki2 } from "../../styles";

interface Slide3Props {
	onNext: () => void;
	sectionOffers?: Array<IShopPurchaseOffer[] & IShopPurchaseOfferSingleItem[]>;
}

export const Slide3 = ({ onNext, sectionOffers }: Slide3Props) => {

	const dispatch = useAppDispatch();
	const isLowHeight = useMediaQuery("(max-height: 600px)");
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const scale = useCalculateScale(
		isPortrait ? 400 : 185,
		isPortrait ? 185 : 500
	);

	const [step, setStep] = useState<number>(1);
	const [offerId, setOfferId] = useState<string>();
	const [shine, setShine] = useState<HTMLElement | null>();
	const [button, setButton] = useState<HTMLElement | null>();
	const [singleItem, setSingleItem] = useState<HTMLElement | null>();
	const [buttonShine, setButtonShine] = useState<HTMLElement | null>();
	const [tutorialPosition, setTutorialPosition] = useState<{ top: number, left: number | string }>({
		top: 285,
		left: isExtraSmall ? "-50%" : "-190px",
	});

	const handleClick = () => {
		if (step === 1) {
			setStep(step + 1);
		}
		if (step === 2) {
			clearStyles();
			onNext();
		}
	};

	const clearStyles = () => {
		button?.removeAttribute("style");
		shine?.classList.remove("animate");
		singleItem?.removeAttribute("style");
		buttonShine?.classList.remove("animate");
	}

	const centerElementWithScroll = (element: HTMLElement) => {
		return new Promise((resolve) => {
			const rect = element.getBoundingClientRect();

			const scrollTop = window.scrollY + rect.top - (window.innerHeight / 2) + (rect.height / 2);
			const scrollLeft = window.scrollX + rect.left - (window.innerWidth / 2) + (rect.width / 2);

			if (Math.abs(window.scrollY - scrollTop) < 1 && Math.abs(window.scrollX - scrollLeft) < 1) {
				resolve({});
				return;
			}

			window.scrollTo({ top: scrollTop, left: scrollLeft, behavior: 'smooth' });

			const handleScroll = () => {
				if (Math.abs(window.scrollY - scrollTop) < 1 && Math.abs(window.scrollX - scrollLeft) < 1) {
					window.removeEventListener('scroll', handleScroll);
					resolve({});
				}
			};

			setTimeout(() => {
				window.removeEventListener('scroll', handleScroll);
				resolve({});
			}, 500);

			window.addEventListener('scroll', handleScroll);
		});
	};

	const scrollAndHighlight = async () => {
		if (step === 1 && singleItem) {
			centerElementWithScroll(singleItem).then(() => {
				// set tutorial postition
				const tutorialPos = singleItem.getBoundingClientRect();
				const centerY =
					isLowHeight
						? (tutorialPos.top + tutorialPos.height / 2) / 2
						: tutorialPos.top + tutorialPos.height + 10;
				const centerX =
					isLowHeight
						? tutorialPos.left + tutorialPos.width + 380 / 2
						: tutorialPos.left + tutorialPos.width / 2 - 380 / 4;
				setTutorialPosition({ top: centerY, left: centerX });

				// set shining position
				if (shine) {
					const centerShineY = tutorialPos.top + tutorialPos.height / 2;
					const centerShineX = tutorialPos.left + tutorialPos.width / 2;
					shine.style.position = "fixed";
					shine.style.top = `${centerShineY}px`;
					shine.style.left = `${centerShineX}px`;
				}
			});
		}
		// set button shining position
		if (step === 2 && button) {
			centerElementWithScroll(button).then(() => {
				// set tutorial postition
				const tutorialPos = button.getBoundingClientRect();
				const centerY =
					isLowHeight
						? (tutorialPos.top + tutorialPos.height / 2) / 2
						: tutorialPos.top + tutorialPos.height + 10;
				const centerX =
					isLowHeight
						? tutorialPos.left + tutorialPos.width + 380 / 2
						: tutorialPos.left + tutorialPos.width / 2 - 380 / 4;
				setTutorialPosition({ top: centerY, left: centerX });

				// set button shining position
				if (buttonShine) {
					const centerShineY = tutorialPos.top + tutorialPos.height / 2;
					const centerShineX = tutorialPos.left + tutorialPos.width / 2;
					buttonShine.style.position = "fixed";
					buttonShine.style.top = `${centerShineY}px`;
					buttonShine.style.left = `${centerShineX}px`;
					buttonShine.style.zIndex = "9999";
				}
			});
		}
	};

	useEffect(() => {
		const offerId = sectionOffers?.[1]?.[isLowHeight ? 0 : 1]?.id;
		setOfferId(offerId);
		const singleItem = document.getElementById(offerId as string);
		setSingleItem(singleItem);
		const shine = document.getElementById(`${offerId}-offer-card-shine`);
		setShine(shine);

		if (step === 1) {
			shine?.classList.add("animate");
			singleItem?.setAttribute("style", "z-index: 9999");
		}

		return () => {
			singleItem?.removeAttribute("style");
			shine?.classList.remove("animate");

			button?.removeAttribute("style");
			buttonShine?.classList.remove("animate");
		}
	}, [isLowHeight]);

	useEffect(() => {
		if (step === 2 && offerId) {
			singleItem?.removeAttribute("style");
			shine?.classList.remove("animate");
			// button
			const button = document.getElementById(`${offerId}-button`);
			setButton(button);
			button?.setAttribute("style", "z-index: 9999");
			// button shine
			const buttonShine = document.getElementById(`${offerId}-offer-card-button-shine`);
			setButtonShine(buttonShine);
			buttonShine?.classList.add("animate");
		}

		return () => {
			button?.removeAttribute("style");
			buttonShine?.classList.remove("animate");
		}
	}, [step, offerId, isLowHeight]);

	useEffect(() => {
		scrollAndHighlight();
	}, [window.innerWidth, window.innerHeight, isExtraSmall, isPortrait, button, buttonShine, singleItem, shine, offerId, isLowHeight]);

	return (
		<Grid
			px={3}
			container
			maxWidth={isLowHeight ? 440 : 380}
			position="fixed"
			top={tutorialPosition.top}
			left={tutorialPosition.left}
			sx={{ transform: `translateX(-50%) scale(${scale})` }}
		>
			{!isLowHeight &&
				<Grid item xs={12} display="flex" justifyContent="flex-end" mr={7}>
					<Arrow src={arrowUp} alt="Arrow up" />
				</Grid>
			}

			{isLowHeight &&
				<Grid item xs={2} display="flex" alignItems="center">
					<Arrow className="side-to-side-left" src={arrowUp} alt="Arrow up" />
				</Grid>
			}

			<Grid item xs={isLowHeight ? 10 : 12}>
				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						{step === 1 ? "3/7" : "4/7"}
					</Text>
				</Grid>

				<Grid item xs={12}>
					<Background2>
						<Grid container mb={3}>
							<Grid item xs={2.5}>
								<Tiki2 src={tiki} alt="Tiki" bottom="13px" />
							</Grid>
							<Grid item xs={9.5}>
								{step === 1
									? (
										<>
											<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
												Get more value and Loyalty Points with web-only deals!
											</Text>
											<Text color="#462C12" fontFamily="Inter" fontSize="12px" lineHeight="15px">
												Redeem points for rewards—watch for bonuses.
											</Text>
										</>
									)
									: (
										<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
											Click an item to purchase securely through the Official Web Store of Tiki Solitaire TriPeaks™.
										</Text>
									)
								}
							</Grid>
						</Grid>
						<Button className="next" onClick={handleClick}>
							Next
						</Button>
						<Button className="skip" variant="text" onClick={() => { clearStyles(); dispatch(onClosePopUp()) }}>
							Skip
						</Button>
					</Background2>
				</Grid>
			</Grid>
		</Grid>
	)
};
