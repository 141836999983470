import classnames from "classnames";
import { Grid, Typography } from "@mui/material";
import crownV2 from "assets/crownV2.png";
import { CrownIcon } from "utils/svgIcons";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { MouseEventHandler, Ref } from "react";
import { CardFinalMessage, LoyaltyPointsV2 } from "styles/general_styles";
import { useAppSelector } from "hooks";
import { selectPoisBountyV2State } from "redux/slices";
import { CtaButton, LoyalBox, TitleImage } from "../styled";
import { useEnvContext, useWebStoreContext } from "contexts";
import { FeaturesFlags } from "helpers";
import useBreakpoints from "hooks/useBreakpoints";

export interface TitleContentProps {
	currentOffer: HTMLButtonElement | null;
	isCurrentOffer: boolean;
	titleImageUrl: string;
	offerId: string;
	buyButtonRef: Ref<HTMLButtonElement | null> | undefined;
	onClickBuy: MouseEventHandler<HTMLButtonElement>;
	ctaText: string;
	loyaltyPointReward: number;
}

export const TitleContent = ({
	currentOffer,
	isCurrentOffer,
	titleImageUrl,
	offerId,
	buyButtonRef,
	onClickBuy,
	ctaText,
	loyaltyPointReward
}: TitleContentProps) => {
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const { isSmUp, isMdUp } = useBreakpoints();
	const { userTracker } = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;

	return (
		<>
			<Grid
				container
				spacing={2}
				direction="column"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
			>
				<Grid item mt={4}>
					<TitleImage className="title-image" data-imageurl={titleImageUrl} />
				</Grid>
				<Grid item width="100%" display="flex" justifyContent="center" mt={-2}>
					<CtaButton
						id={`buy-button-${offerId}`}
						ref={buyButtonRef}
						variant="contained"
						color="success"
						size="large"
						onClick={onClickBuy}
						className={classnames({
							isCurrentOfferSet: !isCurrentOffer && !!currentOffer,
							isCurrentOffer: !!isCurrentOffer
						})}
						sx={{ fontSize: { xs: "88%", sm: "100%" } }}
					>
						{ctaText}
					</CtaButton>
				</Grid>
				{showOfferId && (
					<Grid item>
						<Typography
							sx={{ fontWeight: 700, color: "red", fontSize: "10px" }}
						>
							{offerId}
						</Typography>
					</Grid>
				)}
				<Grid item mb={{ sm: 1 }}>
					{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
						<LoyaltyPointsV2
							padding=".4rem .8rem"
							fontSize={isSmUp ? "88%" : isMdUp ? "90%" : "12px"}
						>
							<div>
								Earn
								<img
									src={crownV2}
									alt="crown-icon"
									width={16}
								/>
								{loyaltyPointReward.toLocaleString("en-US")}
							</div>
							{isPoisActive && (
								<CardFinalMessage color="#FBBD05">
									Contribute to Poi’s Bounty
								</CardFinalMessage>
							)}
						</LoyaltyPointsV2>
					) : (
						<LoyalBox sx={{ fontSize: { xs: "12px", sm: "88%", md: "90%" } }}>
							<div>
								{"Earn"}
								<CrownIcon width={16} />
								{`${loyaltyPointReward.toLocaleString("en-US")}`}
							</div>
							{isPoisActive && (
								<CardFinalMessage color="#FBBD05">
									Contribute to Poi’s Bounty
								</CardFinalMessage>
							)}
						</LoyalBox>
					)}
				</Grid>
			</Grid>
		</>
	);
};
