import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import tiki from "assets/tutorial/tiki-2.png";
import Button from "components/Buttons/ThemeButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { useEffect, useState } from "react";
import { Arrow, Background2, Text, Tiki2 } from "../../styles";

interface Slide2Props {
	onNext: () => void;
}

export const Slide5 = ({ onNext }: Slide2Props) => {
	const isLowHeight = useMediaQuery("(max-height: 600px)");
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const scale = useCalculateScale(
		isPortrait ? 360 : 200,
		isPortrait ? 200 : 300
	);

	const [visible, setVisible] = useState<boolean>(false);
	const [shine, setShine] = useState<HTMLElement | null>();
	const [infoButton, setInfoButton] = useState<HTMLElement | null>();
	const [tutorialPosition, setTutorialPosition] = useState
		<{ top: number, left: number | string, right: number | string }>({
			top: 285,
			left: isExtraSmall ? "-50%" : "-190px",
			right: "unset",
		});

	const waitForScroll = async () => {
		await new Promise((resolve) => {
			const onScrollEnd = () => {
				window.removeEventListener("scrollend", onScrollEnd);
				resolve({});
			};
			window.addEventListener("scrollend", onScrollEnd);
			setTimeout(() => {
				window.removeEventListener("scrollend", onScrollEnd);
				resolve({});
			}, 1000);
		});
	};

	const scrollAndHighlight = async () => {
		if (infoButton) {
			infoButton.scrollIntoView({ behavior: "smooth", block: isLowHeight ? "center" : "end" });
			await waitForScroll();
			const scalePercentageLeft = 1 - scale;
			const scaleCompensation = 1 + scalePercentageLeft / 2;
			const tutorialPos = infoButton.getBoundingClientRect();
			// set tutorial postition
			const centerY = isLowHeight
				? (tutorialPos.top + tutorialPos.height / 2) - 65
				: (tutorialPos.top + tutorialPos.height + 10) * scale;
			//* (tp left pos + half tutorial with - tutorial with) all this centers the tutorial
			//* - half tutorial size + total arrow width * scale compensation
			const centerX =
				isLowHeight
					? (tutorialPos.right * 0.05 + tutorialPos.width + 410 + 10) * scale
					: (tutorialPos.left + tutorialPos.width / 2 - 356 - 356 / 2 + 46) * scaleCompensation;
			setTutorialPosition({ top: centerY, left: centerX, right: centerX });

			// set shining position
			if (shine) {
				const centerShineY = tutorialPos.top + tutorialPos.height / 2;
				const centerShineX = tutorialPos.left + tutorialPos.width / 2;
				shine.style.position = "fixed";
				shine.style.top = `${centerShineY}px`;
				shine.style.left = `${centerShineX}px`;
				shine.style.zIndex = "9999";
			}
			setVisible(true);
		}
	};

	useEffect(() => {
		const infoButton = document.getElementById("tutorial-info-button");
		setInfoButton(infoButton);
		infoButton?.setAttribute("style", "z-index: 9999");

		const shine = document.getElementById("tutorial-info-button-shine");
		setShine(shine);
		shine?.classList.add("animate");
		scrollAndHighlight();

		return () => {
			infoButton?.removeAttribute("style");
			shine?.classList.remove("animate");
		}
	}, []);

	useEffect(() => {
		scrollAndHighlight();
	}, [window.innerWidth, window.innerHeight, isExtraSmall, isPortrait, isLowHeight, infoButton, shine, scale]);

	return (
		<>
			{visible &&
				<Grid
					px={3}
					container
					width={isLowHeight ? 410 : 356}
					position="fixed"
					top={tutorialPosition.top}
					left={isLowHeight ? "unset" : tutorialPosition.left}
					right={isLowHeight ? tutorialPosition.right : "unset"}
					sx={{ transform: `translateX(50%) scale(${scale})` }}
				>
					{!isLowHeight &&
						<Grid item xs={12} display="flex" justifyContent="flex-end" mb={1}>
							<Arrow src={arrowUp} alt="Arrow up" />
						</Grid>
					}
					<Grid item xs={isLowHeight ? 10 : 12}>
						<Grid item xs={12} display="flex" justifyContent="right">
							<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
								7/7
							</Text>
						</Grid>

						<Grid item xs={12}>
							<Background2>
								<Grid container mb={2}>
									<Grid item xs={2.5}>
										<Tiki2 src={tiki} alt="Tiki" bottom="12px" />
									</Grid>
									<Grid item xs={9.5} display="flex" flexDirection="column" gap={1} my={.7}>
										<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
											Enjoy shopping at Solitaire<br /> TriPeaks!
										</Text>
										<Text color="#462C12" fontFamily="Inter" fontSize="12px" lineHeight="15px">
											Revisit the tutorial under info.
										</Text>
									</Grid>
								</Grid>
								<Button className="next" onClick={onNext}>Finish</Button>
							</Background2>
						</Grid>
					</Grid>

					{isLowHeight &&
						<Grid item xs={2} display="flex" alignItems="center">
							<Arrow className="side-to-side-right" src={arrowUp} alt="Arrow up" />
						</Grid>
					}
				</Grid>
			}
		</>
	)
};
