/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from "react";
import { BannerImage, Brush, Container, InfoButton } from "./styles";
import bgBrush from "assets/header-banner/bg-brush-mobile.png";
import infoIcon from "assets/header-banner/info-button.svg";
import { ShopContext } from "pages/StoreFront";
import { ISectionMenuItem } from "../../../../components/PageTopNav/types";
import { getVariantImage } from "utils/getVariantImage";
import { useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { onOpenPopUp, selectPopUpManagerState } from "redux/slices";
import { Shine } from "components/PopUpManager/lib/Tutorial/styles";
import shine from "assets/tutorial/shine.png";

const HeaderBanner = () => {
	const dispatch = useAppDispatch();
	const sectionMenuData: ISectionMenuItem[] =
		useContext(ShopContext)?.sectionMenuData;
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const { variant } = useAppSelector(selectPopUpManagerState);

	const section = sectionMenuData.find(
		(section) => section.name === "shop_header"
	);

	const handleInfoClick = () => {
		if (variant === "tutorial") return;
		dispatch(onOpenPopUp("tutorial"));
	}

	if (!section) {
		return null;
	}

	return (
		<Container id="tutorial-info-button-container">
			<BannerImage
				src={getVariantImage(
					section?.cardBackgroundImageUrl || "",
					"{shop_header_variant}",
					isPortrait ? "small" : "large"
				)}
			/>
			<Shine id="tutorial-info-button-shine" src={shine} alt="shine effect" width={71} />
			<InfoButton
				id="tutorial-info-button"
				src={infoIcon}
				onClick={handleInfoClick}
			/>
			<Brush image={bgBrush}></Brush>
		</Container>
	);
};

export default HeaderBanner;
