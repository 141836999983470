import { Grid, Typography } from "@mui/material";
import loyaltyV2Icon from "assets/loyalty-icon-v2.png";
import classnames from "classnames";
import Button from "components/Buttons/ThemeButton";
import { useEnvContext } from "contexts";
import { useGoogleAnalyticsContext } from "contexts/GoogleAnalyticsProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { IShopPurchaseOfferSingleItem } from "contexts/WebStoreProvider/types";
import { FeaturesFlags } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { WsDialogs } from "interfaces";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	onDIPtrigger,
	onOpenPopUp,
	selectPoisBountyV2State,
	selectPopUpManagerState
} from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { RootState } from "redux/store";
import { CardFinalMessage } from "styles/general_styles";
import { InfoIcon } from "utils/svgIcons";
import { FirstPurchaseBanner } from "./lib/FirstPurchaseBanner";
import { BonusFlag } from "./lib/Flag";
import {
	CrossOutLine,
	InfoButton,
	RewardContainer,
	RewardImage,
	RewardNumber,
	StyledCard,
	WebBonusText
} from "./styled";
import { Shine } from "components/PopUpManager/lib/Tutorial/styles";
import shine from "assets/tutorial/shine.png";
//import useRewardsById from "hooks/useRewardsById";

const OfferCardV2 = ({
	data,
	itemRef
}: {
	data: IShopPurchaseOfferSingleItem;
	itemRef?: React.MutableRefObject<HTMLDivElement | null>;
}): JSX.Element => {
	const {
		bannerText,
		campaignGroup,
		description,
		firstPurchaseRewardBonus,
		id: offerId,
		loyaltyPointCrossout,
		loyaltyPointReward,
		onlyNonPayers,
		price,
		priority,
		purchaseLimit,
		rewardCrossout,
		rewardId,
		rewardImageUrl,
		sectionName
	} = data;
	const {
		authToken,
		currentOffer,
		handleRefresh,
		openLoginToContinueDialog,
		postUserMetadata,
		setActiveItemToBeClicked,
		setCurrentOffer,
		store,
		suppressOrderConfirmation,
		userTracker,
		wsStoreBuy
	} = useWebStoreContext();
	const buyButtonRef = useRef(null);
	const dispatch = useAppDispatch();
	const { openDialog } = useDialogModalRedux();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { variant } = useAppSelector(selectPopUpManagerState);
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);

	const [isCurrentOffer, setIsCurrentOffer] = useState<boolean>(false);
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	//const displayRewards = useRewardsById(rewardId);

	const resetCurrentOffer = () => {
		setIsCurrentOffer(false);
		setCurrentOffer(null);
	};

	const cbClose = () => {
		resetCurrentOffer();
		reportEvent("dialog_x_closed", { source: "xsolla_dialog" });
		handleRefresh();
		dispatch(onDIPtrigger("cache-mode"));
	};

	const firstPurchaseText = useMemo(() => {
		return (
			firstPurchaseRewardBonus &&
			store?.globalConfig
				?.find((c) => c.id === "first_purchase_badge_text")
				?.value?.replace(
					"{amount}",
					Number(firstPurchaseRewardBonus).toLocaleString("en-US")
				)
		);
	}, [firstPurchaseRewardBonus, store?.globalConfig]);

	const onClickBuy = async () => {
		if (variant === "tutorial") return;
		// setCurrentOffer used for button states
		setCurrentOffer(buyButtonRef.current);
		if (authToken?.value) {
			const invoice = await wsStoreBuy({ offerId });
			if (invoice) {
				console.log("suppressOrderConfirmation", suppressOrderConfirmation);
				openDialog(WsDialogs.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					source: "offer_card",
					cbClose,
					suppressOrderConfirmation,
					postUserMetadata,
					showClose: true,
					onPurchaseStarted: (data) => {
						reportEvent("purchase_started", {
							tuid: userTracker?.id as string,
							offer_id: offerId,
							reward_id: rewardId,
							priority: priority,
							price_in_cents: Math.round(price * 100),
							campaign_group: campaignGroup,
							section_name: sectionName,
							only_non_payers: onlyNonPayers,
							loyalty_point_crossout:
								typeof loyaltyPointCrossout !== "undefined",
							items_crossout: typeof rewardCrossout !== "undefined",
							firstPurchaseText,
							first_purchase_bonus: firstPurchaseText,
							invoice_id: invoice?.id,
							paystation_id: data?.payStation
						});
					},
					onPurchaseSuccess: () => {
						reportEvent("purchase_success", {
							invoice_id: invoice?.id
						});
					}
				});
			} else {
				resetCurrentOffer();
			}

			//NOTE: bfloyd error cases already handled for undefined/null invoice
		} else {
			// setActiveItemToBeClicked used for re-opening after authentication
			setActiveItemToBeClicked({
				type: "store_offer",
				offerId,
				onlyNonPayers,
				purchaseLimit
			});
			openLoginToContinueDialog();
		}
	};

	useEffect(() => {
		if (currentOffer === buyButtonRef.current) {
			setIsCurrentOffer(true);
		} else {
			setIsCurrentOffer(false);
		}
	}, [currentOffer]);

	return (
		<Grid item position="relative" id={offerId}>
			<Shine id={`${offerId}-offer-card-shine`} src={shine} width={354} />
			{bannerText && <BonusFlag text={bannerText} />}
			<InfoButton
				aria-label="open info popup"
				onClick={() => {
					variant !== "tutorial" &&
						dispatch(onOpenPopUp(`single-item-info-${offerId}`));
				}}
			>
				<InfoIcon width={24} height={24} />
			</InfoButton>
			<StyledCard ref={itemRef}>
				<Grid container spacing={0.4} justifyContent="center" mt={4.8} mb={1.2}>
					<Grid item xs={6} display="flex" justifyContent="center">
						<RewardContainer>
							{isFeatureFlagEnabled(FeaturesFlags.FIRST_PURCHASE_BONUS) && variant !== "tutorial" &&
								firstPurchaseText && (
									<FirstPurchaseBanner
										firstPurchaseText="FIRST PURCHASE"
										firstPurchaseRewardBonus={`+${new Intl.NumberFormat(
											"en-US",
											{
												notation: "compact",
												compactDisplay: "short",
												maximumFractionDigits: 1,
												roundingMode: "floor"
											}
										).format(Number(firstPurchaseRewardBonus))}`}
									/>
								)}
							<RewardImage src={rewardImageUrl} />
							{rewardCrossout && (
								<RewardNumber className="cross-out">
									{Number(rewardCrossout.replace(/[^0-9]/g, "")).toLocaleString(
										"en-US"
									)}
									<CrossOutLine />
								</RewardNumber>
							)}
							<RewardNumber>
								{Number(description.replace(/[^0-9]/g, "")).toLocaleString(
									"en-US"
								)}
							</RewardNumber>
						</RewardContainer>
					</Grid>
					<Grid item xs={6} display="flex" justifyContent="center">
						<RewardContainer>
							<WebBonusText>Web bonus:</WebBonusText>
							<RewardImage
								src={loyaltyV2Icon}
								isLp={true}
								alt="Loyalty points icon"
							/>
							{loyaltyPointCrossout && (
								<RewardNumber className="cross-out">
									{Number(loyaltyPointCrossout).toLocaleString("en-US")}
									<CrossOutLine />
								</RewardNumber>
							)}
							<RewardNumber>
								{Number(loyaltyPointReward).toLocaleString("en-US")}
							</RewardNumber>
						</RewardContainer>
					</Grid>
				</Grid>
				<Grid item position="relative" id={`${offerId} button`}>
					<Shine id={`${offerId}-offer-card-button-shine`} src={shine} width={200} />
					<Button
						id={`${offerId}-button`}
						ref={buyButtonRef}
						variant="contained"
						color="success"
						size="large"
						onClick={onClickBuy}
						sx={{
							width: "100%",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem"
						}}
						className={classnames({
							isCurrentOffer,
							isCurrentOfferSet: !!currentOffer
						})}
					>
						{price} USD
					</Button>
				</Grid>
				{isPoisActive && (
					<CardFinalMessage color="#000" opacity={0.6} className="mt">
						Contribute to Poi’s Bounty
					</CardFinalMessage>
				)}
				{showOfferId && (
					<Typography
						sx={{
							fontWeight: 700,
							color: "red",
							fontSize: "10px",
							height: "auto",
							textAlign: "center",
							marginTop: 1
						}}
					>
						{offerId}
					</Typography>
				)}
			</StyledCard>
		</Grid>
	);
};

OfferCardV2.displayName = "OfferCard";

export { OfferCardV2 };
