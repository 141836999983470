/* eslint-disable @typescript-eslint/no-unused-vars */
import MainMenu from "./lib/MainMenu";
import useBreakpoints from "hooks/useBreakpoints";
import { useEffect, useRef, useState } from "react";
import {
	Slide,
	Container,
	useScrollTrigger,
	useMediaQuery
} from "@mui/material";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { getNavigationMenu } from "utils/getNavigationMenu";
import { ISectionMenuItem } from "./types";
import { useAppSelector } from "hooks";
import { selectPoisBountyState, selectPoisBountyV2State } from "redux/slices";
import {
	MainMenuContainer,
	mainMenuContainerInnerSxProps,
	Nav,
	SectionMenuContainer,
	sectionMenuContainerSxProps,
	sectionMenuInnerSxProps,
} from "./styled";
import { SectionMenu } from "./lib/SectionMenu";

const PageTopNavV2 = ({
	sectionMenuData: sectionItems,
	isAppBarFixed = true,
	isLandingPage = false,
	navItemColor,
	hasSectionBanner = false
}: {
	sectionMenuData: ISectionMenuItem[];
	className?: string;
	isAppBarFixed?: boolean;
	isLandingPage?: boolean;
	navItemColor?: string;
	hasSectionBanner?: boolean;
}): JSX.Element => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const trigger = useScrollTrigger({ threshold: 35 });
	const sliderRef = useRef<HTMLElement | null>(null);
	const { isSmDown, isLgDown } = useBreakpoints();
	const isHideOnScrollEnabled = false;
	const top = (() => {
		if (isAppBarFixed) {
			if (isHideOnScrollEnabled && trigger) {
				return "0"; // when AppBar hidden (if HideOnScroll enabled)
			}
			return isSmDown ? "82px" : isLgDown ? "63px" : "65px";
		}
		// absolute app bar
		return "40%";
	})();

	const isPortrait = useMediaQuery("(orientation: portrait)");

	// this disables the transition effect when switching between "pages"
	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 0);
	}, []);

	const { userTracker, nowTime } = useWebStoreContext();
	const { isEventActive } = useAppSelector(selectPoisBountyState);
	const { isEventActiveV2 } = useAppSelector(selectPoisBountyV2State);
	const isPoisActive: boolean = isEventActive || isEventActiveV2;

	const navigation = getNavigationMenu(
		userTracker?.clubId,
		isPoisActive,
		userTracker,
		nowTime
	).filter(
		(navItem) =>
			!navItem?.isExternal &&
			navItem?.isEnabled &&
			navItem?.isDisplayInPageTopNav
	);

	return (
		<>
			<Slide
				ref={sliderRef}
				appear={false}
				direction="down"
				in={true}
				style={{
					zIndex: 100
				}}
				timeout={{ enter: 300, exit: 300 }}
			>
				<MainMenuContainer
					nav-item-color={navItemColor}
					className="main-menu-container"
					sx={{
						background: isLandingPage ? "none" : "",
						a: {
							color: isLandingPage ? "white" : "",
							textShadow: isLandingPage ? "1px 1px 1px #444" : ""
						}
					}}
				>
					<Container
						className="main-menu-container-inner"
						sx={{
							...mainMenuContainerInnerSxProps,
							padding: { xs: "0px", lg: "0px" }
						}}
					>
						<Nav>
							<MainMenu pages={navigation} />
						</Nav>
					</Container>
				</MainMenuContainer>
			</Slide>
			<SectionMenuContainer
				id="sub-menu"
				className="section-menu-container"
				sx={{
					...sectionMenuContainerSxProps,
					width: "100%",
					marginTop: hasSectionBanner ? (isPortrait ? "40%" : "20%") : "0px",
					top: { xs: top || "82px", sm: top || "65px", lg: "60px" },
					transition: isLoaded ? "top 0.18s linear" : "none",
					position: "sticky"
				}}
			>
				<Container
					className="section-menu-inner"
					sx={{ ...sectionMenuInnerSxProps }}
				>
					<SectionMenu items={sectionItems} />
				</Container>
			</SectionMenuContainer>
		</>
	);
};

export default PageTopNavV2;
export * from "./types";
