import styled from "styled-components";

export const defaultTextColor = "#073670";

export const SectionTitle = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: ${defaultTextColor};
	display: flex;
	align-items: center;
	margin: 10px 0px 0px 0px;
	font-family: Baloo;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;

	@media (min-width: 550px) {
		font-size: 34px;
	}
`;

export const SectionIconContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 5px;
`;

export const CardFinalMessage = styled.div<{ fontSize?: string, opacity?: number }>`
	color: ${(props) => props.color || defaultTextColor};
	opacity: ${(props) => props.opacity || 1};
	font-weight: 700;
	font-size: ${(props) => props.fontSize || "0.92em"};
	line-height: 1em;
	text-align: center;

	&.mt {
		margin-top: 0.6em;
	}

	&.mb {
		margin-bottom: 0.6em;
	}

	&.with-contrast {
		text-shadow: 0px 0px 0 #474747, -1px 1px 0 #474747, 1px -1px 0 #474747,
			-1px -1px 0 #474747, 0px 1px 0 #474747, 0px -1px 0 #474747,
			-1px 0px 0 #474747, 1px 0px 0 #474747, 1px 1px 0 #474747,
			-1px 1px 0 #474747, 1px -1px 0 #474747, -1px -1px 0 #474747,
			0px 1px 0 #474747, 0px -1px 0 #474747, -1px 0px 0 #474747,
			1px 0px 0 #474747, 1px 1px 0 #474747, -1px 1px 0 #474747,
			1px -1px 0 #474747, -1px -1px 0 #474747, 1px 1px 0 #474747,
			-1px 1px 0 #474747, 1px -1px 0 #474747, -1px -1px 0 #474747;
	}

	@media (max-width: 430px) {
		font-size: 14px;
	}
	@media (max-width: 414px) {
		font-size: 13px;
	}
	@media (max-width: 390px) {
		font-size: 12px;
	}
	@media (max-width: 375px) {
		font-size: 10px;
	}
	@media (max-width: 932px) and (max-height: 430px) {
		font-size: 0.8em;
	}

	&.waterfall {
		@media (min-width: 0px) {
			font-size: 1.7vw;
		}
		@media (min-width: 600px) {
			font-size: 0.8vw;
		}
		@media (min-width: 900px) {
			font-size: 0.9vw;
		}
		@media (min-width: 1200px) {
			font-size: 0.9vw;
		}
		@media (min-width: 1536px) {
			font-size: 0.7vw;
		}
	}
`;

export const SwiperContainer = styled.div`
	.swiper-pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 10px;
	}

	.swiper-pagination-bullet {
		width: 7px;
		height: 7px;
		opacity: 1;
		background: #01a1bd;
	}

	.swiper-pagination-bullet-active {
		width: 12px;
		height: 12px;
		background: #eb5757;
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: unset;
		bottom: 0;
		margin: 0 10px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		color: white;
		width: 32px;
		height: 32px;

		&:after {
			font-size: 12px;
			font-weight: bold;
			-webkit-text-stroke-width: 0.5px;
			-webkit-text-stroke-color: white;
		}

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const LoyaltyPointsV2 = styled.div<{
	fontSize?: string;
	padding?: string;
	position?: string;
}>`
	position: ${(props) => props.position || "relative"};
	border-radius: 100px;
	border: 1px solid #44c8df;
	background: rgba(14, 144, 167, 0.7);
	color: #fff;
	font-size: ${(props) => props.fontSize || "12px"};
	line-height: ${(props) => props.fontSize || "12px"};
	font-weight: 700;
	padding: ${(props) => props.padding || "4px 6px"};
	min-width: max-content;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3px;
	}
`;
