import { useMediaQuery } from "@mui/material";
import avatar from "assets/pois-bounty/avatar-pois-bounty.png";
import mobileBackground from "assets/pois-bounty/bg-page-mobile-pois-bounty.jpg";
import background from "assets/pois-bounty/bg-page-pois-bounty.jpg";
import titleMobile from "assets/pois-bounty/title-mobile-pois-bounty.png";
import defaultBackgroundImgMobile from "assets/pois-bounty/v2/default-background-image-mobile.png";
import defaultBackgroundImg from "assets/pois-bounty/v2/default-background-image.png";
import defaultTitle from "assets/pois-bounty/v2/pois-logo.png";
import PoisBountyWidget from "components/PoisBountyWidget";
import PoisBountyWidgetV2 from "components/PoisBountyWidgetV2";
import { useEnvContext } from "contexts/EnvProvider";
import { useWebStoreContext } from "contexts/WebStoreProvider";
import { FeaturesFlags } from "helpers";
import { PrivateRoute } from "hoc";
import { useAppSelector } from "hooks";
import { ReactElement, useEffect, useRef, useState } from "react";
import { selectPoisBountyState, selectPoisBountyV2State } from "redux/slices";
import { useInitialActions } from "./hooks/useInitialActions";
import {
	Avatar,
	Background,
	Box1,
	Container,
	PoisBountyWrapper,
	TitleMobile,
	TitleMobileV2,
	TitleV2
} from "./styles";
import PageTopNav, { ISectionMenuItem } from "../../components/PageTopNav";
import PageTopNavV2 from "../../components/PageTopNavV2";

const PoisBounty = () => {
	const isLowWidth = useMediaQuery("(max-width:650px)");
	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);
	const [bgImg, setBgImg] = useState<string | undefined>();
	const [title, setTitle] = useState<
		ReactElement<string, string> | undefined
	>();
	const { userTracker } = useWebStoreContext();
	const { tracker } = useAppSelector(selectPoisBountyState).data;
	const { trackers } = useAppSelector(selectPoisBountyV2State);
	const { isFeatureFlagEnabled } = useEnvContext();
	const poisBountyRef = useRef<HTMLDivElement>(null);
	useInitialActions();

	const handleScrollToElement = () => {
		if (poisBountyRef.current) {
			poisBountyRef.current.scrollIntoView({
				behavior: "smooth",
				block: "end"
			});
		}
	};

	function getBackgroundUrl(urlTemplate: string, variant: string): string {
		return urlTemplate?.replace("{pois_bounty_variant}", variant);
	}

	const selectBackgroundImg = () => {
		let bgImg: string = isLowWidth ? mobileBackground : background;
		if (isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) {
			if (trackers.backgroundImageUrl) {
				bgImg = isLowWidth
					? getBackgroundUrl(trackers.backgroundImageUrl, "small")
					: getBackgroundUrl(trackers.backgroundImageUrl, "large");
			} else {
				bgImg = isLowWidth ? defaultBackgroundImgMobile : defaultBackgroundImg;
			}
		}
		setBgImg(bgImg);
	};

	const selectTitle = () => {
		let title = isLowWidth ? (
			<TitleMobile src={titleMobile} />
		) : (
			<Avatar src={avatar} />
		);
		if (isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) {
			if (trackers.titleImageUrl) {
				title = isLowWidth ? (
					<TitleMobileV2
						src={getBackgroundUrl(trackers.titleImageUrl, "small")}
					/>
				) : (
					<TitleV2 src={getBackgroundUrl(trackers.titleImageUrl, "large")} />
				);
			} else {
				title = isLowWidth ? (
					<TitleMobileV2 src={defaultTitle} />
				) : (
					<TitleV2 src={defaultTitle} />
				);
			}
		}
		setTitle(title);
	};

	useEffect(() => {
		handleScrollToElement();
	}, []);

	useEffect(() => {
		if (
			Object.keys(trackers).length ||
			(tracker && Object.keys(tracker).length)
		) {
			selectBackgroundImg();
			selectTitle();
		}
	}, [tracker, trackers, isLowWidth]);

	return (
		<PrivateRoute
			isEnabled={
				(isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) ||
					isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2)) &&
				!!userTracker
			}
		>
			<Container>
				{bgImg && <Background bgImg={bgImg} />}
				{title && title}
				{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
					<PageTopNavV2
						navItemColor="#FFE37E"
						sectionMenuData={sectionMenuData}
						isLandingPage
					/>
				) : (
					<PageTopNav
						navItemColor="#FFE37E"
						sectionMenuData={sectionMenuData}
						isLandingPage
					/>
				)}
				<PoisBountyWrapper ref={poisBountyRef}>
					<Box1 className="animate__animated animate__bounceInDown">
						{isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY_V2) ? (
							<PoisBountyWidgetV2 isPageVariant isPortrait={isLowWidth} />
						) : (
							isFeatureFlagEnabled(FeaturesFlags.POIS_BOUNTY) && (
								<PoisBountyWidget isPageVariant isPortrait={isLowWidth} />
							)
						)}
					</Box1>
				</PoisBountyWrapper>
			</Container>
		</PrivateRoute>
	);
};

export default PoisBounty;
