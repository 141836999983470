import { useEffect } from "react";
import { useEnvContext } from "contexts";
import { DailyLoginStreak } from "./lib";
import { useInitialActions } from "./hooks";
import { FeaturesFlags, links } from "helpers";
import { DailyGiftIcon } from "utils/svgIcons";
import PageTopNav from "components/PageTopNav";
import { useNavigate } from "react-router-dom";
import { PageContent } from "components/PageContent";
import { SectionIconContainer, SectionTitle } from "styles/general_styles";
import PageTopNavV2 from "../../components/PageTopNavV2";

const DailyGift = () => {
	useInitialActions();
	const navigate = useNavigate();
	const { isFeatureFlagEnabled } = useEnvContext();

	useEffect(() => {
		!isFeatureFlagEnabled(FeaturesFlags.DAILY_BONUS) &&
			navigate(links.home.path);
	}, [FeaturesFlags]);

	return (
		<>
			{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
				<PageTopNavV2
					navItemColor="#FFE37E"
					sectionMenuData={[]}
					isLandingPage
				/>
			) : (
				<PageTopNav navItemColor="#FFE37E" sectionMenuData={[]} isLandingPage />
			)}
			<PageContent source="daily_gift_page">
				<SectionTitle>
					<SectionIconContainer>
						<DailyGiftIcon width="25px" height="40px" fill="#073670" />
					</SectionIconContainer>
					<div>{links.free_daily_gifts.title.toUpperCase()}</div>
				</SectionTitle>
				<DailyLoginStreak />
			</PageContent>
		</>
	);
};

export default DailyGift;
