import { Grid } from "@mui/material";
import tiki from "assets/tutorial/tiki-1.png";
import Button from "components/Buttons/ThemeButton";
import { useAppDispatch } from "hooks";
import { onClosePopUp } from "redux/slices";
import { Triangle } from "./triangle";
import { Background1, Text, Tiki1 } from "../../styles";

interface Slide1Props {
	onNext: () => void;
}

export const Slide1 = ({ onNext }: Slide1Props) => {

	const dispatch = useAppDispatch();

	return (
		<>
			<Grid container spacing={1} px={1}>
				<Grid item xs={12} display="flex" justifyContent="right">
					<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
						1/7
					</Text>
				</Grid>

				<Grid item xs={4} position="relative">
					<Tiki1 src={tiki} alt="Tiki" />
				</Grid>

				<Grid item xs={8}>
					<Grid container spacing={1}>

						<Grid item xs={12}>
							<Background1>
								<Text fontFamily="Baloo" fontSize="16px" lineHeight="17px">
									Welcome to the Tiki<br /> Solitaire TriPeaks Web<br /> Shop! Let’s explore
								</Text>
								<Text fontFamily="Inter" fontSize="12px" fontWeight={700} lineHeight="15px">
									Find exclusive offers, Loyalty<br /> Points, and special web-only<br /> deals.
								</Text>
								<Triangle />
							</Background1>
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Button sx={{ width: "100%" }} onClick={onNext}>Next</Button>
								</Grid>
								<Grid item xs={6} display="flex" justifyContent="right">
									<Button variant="text" onClick={() => dispatch(onClosePopUp())}>Skip</Button>
								</Grid>
							</Grid>
						</Grid>

					</Grid>
				</Grid>
			</Grid>
		</>
	)
};
