import { AxiosResponse, AxiosStatic } from "axios";
import { ReactNode } from "react";
import { IDebugLogger } from "../../utils/debugLogger";
import { IGetGetStoreReturnType } from "./api/getStore";
import { ISyncUserTrackerReturnType } from "./api/syncUserTracker";
import { Moment } from "moment";
import LoginService from "../../domain/Login/LoginService";
import { IPostUserMetadataReturnType } from "./api/postUserMetadata";
import { GlobalConfig, SaleTag } from "interfaces";

export interface IAuthToken {
	value: string;
	expiresAt: string;
}

export interface IAbstractResponse {
	success?: boolean;
	errorCode?: string;
	errorMessage?: string;
}

export interface IAuthResponse extends IAbstractResponse {
	value: string;
	expiresAt: string;
}

export interface ILoginResponseFirstLoginReward {
	id: string;
	endTime: string;
	loyaltyPointReward: number;
	description: string;
	bannerText: string;
}

// Paralell to com.gsngames.pubtech.webstore.user.UserController.LoginResponse
export interface ILoginResponse {
	success: boolean;
	errorCode: string;
	errorMessage: string;
	authToken: IAuthToken;
	userTracker: IUserTracker;
	firstLoginReward: ILoginResponseFirstLoginReward;
	nextDailyGiftTime: string;
	profilePictureUrl: string;
	helpshiftToken: string;
	userName: string;
	name: string;
}

export interface IInvoiceReponse extends IAbstractResponse {
	invoice: IInvoice;
	userTracker: IUserTracker;
}

export interface IInvoice {
	id: string;
	appId: string;
	userId: string;
	email: string;
	offerId: string;
	offerDescription: string;
	price: number;
	loyaltyPoints: number;
	paymentCount: number;
	dryRun: boolean;
	xsollaToken: string;
	xsollaHost: string;
	fulfilled: boolean;
	createdEpochMillis: number;
	createdDateTime: string;
}
/* eslint-disable */
export interface IShopStore {
	globalImages: IGlobalImage[];
	firstLoginRewards: IShopLoginReward[];
	rewardTypes: any;
	mainShopSections: IShopSection[];
	loyaltyShopSections: IShopSection[];
	purchaseOffers: (
		| IShopPurchaseOfferSingleItem
		| IShopPurchaseOfferMultiItem
		| IShopPurchaseOfferClub
	)[];
	loyaltyOffers: IShopLoyaltyOffer[];
	rewards: IShopRewards;
	dailyGiftImages: IShopDailyGiftImages;
	metadata: IShopMetadata;
	namespace: string;
	serverNamespace: string;
	version: string;
	nowTime: string;
	saleTags: SaleTag[];
	clubGifts: IClubGift[];
	globalConfig: GlobalConfig[];
}
/* eslint-enable */

export interface IGlobalImage {
	id: string;
	imageUrl: string;
}
export interface IShopLoginReward {
	bannerText: string;
	description: string;
	id: string;
	rewardId: string;
	startTime?: string;
	endTime?: string;
}

export interface IShopDailyGiftImages {
	claimTitleImageUrl: string;
	collectedTitleImageUrl: string;
	claimImageUrl: string;
	collectedImageUrl: string;
	confirmationImageUrl: string;
}

export interface IShopSection {
	id: string;
	startTime: string;
	endTime: string;
	name: string;
	title: string;
	priority: number;
	cardBackgroundImageUrl: string;
	iconImageUrl: string;
}

export interface IDip {
	id: string;
	campaignGroup: string;
	headerImageUrl: string;
	imageUrl: string;
	bogoType: string;
	isBogo?: boolean;
	isBuyItAll?: boolean;
	//isDoubleBogo?: boolean;
}

export interface IShopPurchaseOffer {
	timer?: string;
	startTime: string;
	endTime: string;
	id: string;
	timerExpirationDate?: string;
	priority: number;
	rewardId: string;
	rewardMultiplier?: number;
	description: string;
	bannerText: string;
	excludePromotion?: boolean;
	segmentId?: string;
	shopSectionExpirationDate?: string;
	purchaseButtonBannerText: string;
	loyaltyPointReward: number;
	campaignGroup: string;
	onlyNonPayers: boolean;
	price: number;
	sectionName: string;
	type: string;
	uniqueKey?: string;
	purchaseLimit?: number;
	dip?: IDip;
	isBogo?: boolean;
	isBuyItAll?: boolean;
	badgeText?: string;
	badgeImageUrl?: string;
	offerMetadata?: {
		dipViewedDate?: string;
	}
}

export interface IShopPurchaseOfferSingleItem extends IShopPurchaseOffer {
	rewardImageUrl: string;
	firstPurchaseBonusImageUrl?: string;
	crossOutValue?: string;
	loyaltyPointCrossout?: string;
	rewardCrossout?: string;
	firstPurchaseRewardBonus?: string;
}

export interface IShopPurchaseOfferMultiItem extends IShopPurchaseOffer {
	titleImageUrl: string;
	backgroundImageUrl: string;
}

export interface IShopPurchaseOfferClub extends IShopPurchaseOffer {
	clubGiftTripeaksBundleId?: string;
	loyaltyPointCrossout?: number;
	rewardCrossout?: number;
}

export interface IShopLoyaltyOffer {
	id: string;
	timer?: string;
	startTime: string;
	endTime: string;
	loyaltyPointPrice: number;
	rewardId: string;
	rewardImageUrl: string;
	description: string;
	imageUrl: string;
	sectionName: string;
	campaignGroup: string;
	priority: number;
	bannerText: string;
	purchaseButtonBannerText: string;
	crossOutValue?: string;
}

export interface IShopRewards {
	[key: string]: IShopReward;
}
/* eslint-disable */
export interface IShopReward {
	type: any;
	id: string;
	items: Map<string, number>;
}
/* eslint-enable */
export interface IShopRewardItem {
	value: ReactNode;
	imageUrl: string;
	id: string;
}

export interface IShopMetadata {
	version: string;
}

export interface IUserPurchaseTracker {
	count: number;
	offerId: string;
	offerType: string;
	firstPurchasedDate: string;
	lastPurchasedDate: string;
}

export interface IUserTrackerResponse extends IAbstractResponse {
	userTracker: IUserTracker;
}
export interface IUserTracker {
	clubId: string | undefined;
	now: string;
	id: string;
	appId: string;
	version: string;
	dailyLoginStreak: number;
	lastDailyGiftCollectedDate: string;
	lastLoginDate: string;
	loyaltyPoints: number;
	firstLoginRewardDate: string;
	isFirstLogin?: boolean;
	totalSpend: number;
	totalLoyaltyPointSpend: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	loyaltyTrackers: any[];
	metadata: {
		userData: {
			[key: string]: string;
		};
		hasSeenTutorial?: string;
		hasDisplayedClubGiftGaloreFtue: string;
		lastSeenLimitedDealSectionV2?: string;
		poisBountyFtue?: string;
		suppressOrderConfirmation?: string;
	};
	clubName?: string;
	name: string;
	inventory: IInventory;
}
export interface IInventory {
	booster_defroster: number;
	booster_defuser: number;
	booster_hook: number;
	booster_streak: number;
	booster_trap: number;
	booster_wave: number;
	coins: number;
	free_plays: number;
	volcanos: number;
	wilds: number;
	[key: string]: number;
}
export interface IWebStoreContext {
	store: IShopStore | null;
	authToken: IAuthToken | null;
	userTracker: IUserTracker | null;
	buy(props: IBuyProps): Promise<IInvoice | undefined>;
	loyaltyBuy(
		offerId: string,
		cbClose: () => void
	): Promise<IInvoice | undefined | number>;
	wsStoreBuy(props: IBuyProps): Promise<IInvoice | undefined>;
	wsLoyaltyBuy(offerId: string): Promise<IInvoice | undefined>;

	syncUserTracker: ISyncUserTrackerReturnType;

	// eslint-disable-next-line
	handleErrorResponse(response: any): void;

	serverNamespace: string | null;
	namespaceOverride: string | null | undefined;
	setNamespaceOverride: (_namespace: string | null) => void | null;
	dryRunOverride: string | null | undefined;
	setDryRunOverride: (_dryRunOverride: string | null) => void | null;
	openLoginToContinueDialog(): void;
	reProcessStoreRaw: (debug?: IDebugLogger) => void;
	forceReloadStoreRaw: (debug?: IDebugLogger) => void;
	createHeaders: (headers: IHeaders) => IHeaders;
	setAuthToken: (authToken: IAuthToken | null) => void;
	logout: () => void;
	fbAccessToken: string | null;
	profileImage: string | null;
	userName: string | null;
	setCurrentOffer: React.Dispatch<
		React.SetStateAction<HTMLButtonElement | null>
	>;
	currentOffer: HTMLButtonElement | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activeItemToBeClicked: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setActiveItemToBeClicked: React.Dispatch<React.SetStateAction<any | null>>;
	handleRefresh: () => void;
	setNowTime: (now: string) => void;
	nowTime: Moment | null;
	networkErrorDetected: boolean;
	handleAdsPersonalizationChange: () => void;
	suppressOrderConfirmation: string;
	postUserMetadata: IPostUserMetadataReturnType;
	immediateLogin: (token: string) => void;
	isAutoLoginIn: boolean;
	isAutoLogedIn: boolean;
	autoLoginExpiredToken: string | null;
}

//TODO: fix any
/* eslint-disable */
export interface IPopupsProp {
	showErrorDialog: any;
	showErrorDialogHelper: any;
	openDialog: any;
	handleErrorResponse: any;
}
/* eslint-enable */

/* eslint-disable */
export enum WsDialogs {
	NONE = "none",
	DEFAULT = "default",
	CLAIM_MY_REWARD = "claim-my-reward",
	LOGIN_TO_CONNECT = "login-to-connect",
	ACCOUNT_NOT_CONNECTED = "account-not-connected",
	DAILY_GIFT_CONFIRMATION = "daily-gift-confirmation",
	RECEIVED_FIRST_LOGIN_REWARD = "received-first-login-reward"
}
/* eslint-enable */

export interface IWebStoreContextApiProps {
	axios: AxiosStatic;
	authToken: IAuthToken | null;
	backendUri: string;
	popups: IPopupsProp;

	// eslint-disable-next-line
	createHeaders(headers: any): any;

	dryRunOverride: string | null;
	// eslint-disable-next-line
	reportEvent: (eventName: string, eventValues: any) => void;
	debug: IDebugLogger;
	syntheticId: string;
	loginService: LoginService;
}

/* eslint-disable */
export interface IWebStoreContextApi {
	getStore: IGetGetStoreReturnType;
	syncUserTracker: ISyncUserTrackerReturnType;
	postUserMetadata: IPostUserMetadataReturnType;
	buy: (props: IBuyProps) => Promise<IInvoice | undefined>;
	loyaltyBuy: (offerId: string) => Promise<IInvoice | undefined>;
	logout: () => Promise<void>;
	checkUserEligibility: (type: string, params: any) => boolean;
}
/* eslint-enable */

export interface IWsStoreBuyProps {
	offerId: string;
}
export interface IWsLoyaltyBuyProps {
	offerId: string;
}
export interface IWsLoyaltyBuyProps {
	offerId: string;
	resetCurrentOffer: () => void;
	description: string;
	price: number;
	rewardImageUrl: string;
}

export type IShopFilterableItem =
	| IShopPurchaseOfferSingleItem
	| IShopPurchaseOfferMultiItem
	| IShopLoyaltyOffer
	| IShopSection
	| IShopLoginReward
	| IShopPurchaseOfferClub;

export type IShopSortableItem =
	| IShopPurchaseOfferSingleItem
	| IShopPurchaseOfferMultiItem
	| IShopLoyaltyOffer
	| IShopSection
	| IShopPurchaseOfferClub;

export interface IHeaders {
	[key: string]: string;
}

export interface IPostUserMetadataOptions {
	key: string;
	value: string;
}

export interface IPostUserMetadataOptions {
	key: string;
	value: string;
}

export interface IClubGift {
	id: string;
	randomRewardCount: number;
	randomRewardCrossout?: number;
	loyaltyPointReward: number;
	thankYouCoins: number;
	thankYouCoinsCrossout: number;
	priority: number;
	chestImageUrl: string;
	thankYouCoinsImageUrl: string;
	randomRewardImageUrl: string;
	rewardDescription: string;
	rewards?: IClubGiftReward[];
	bannerGlobalImageId?: string;
	highlightReward?: boolean;
	highlightReward2?: boolean;
	highlightRandomReward?: boolean;
}

export interface IClubGiftReward {
	id: string;
	description: string;
}

export interface IWebStoreAxiosErrorResponse extends AxiosResponse {
	data: {
		errorCode: string;
		errorMessage: string;
	};
}

export interface IWebStoreGeneralErrorResponse {
	data: {
		errorCode: string;
		errorMessage: string;
	};
}

export interface IBuyProps {
	offerId: string;
	waterfallSaleId?: string;
	waterfallSaleTierIndex?: number;
	endlessOfferEventId?: string;
	endlessOfferNodeIndex?: number;
}
