import { Container, Grid, useMediaQuery } from "@mui/material";
import CountdownV2 from "components/CountdownV2";
import PageTopNav, { ISectionMenuItem } from "components/PageTopNav";
import PageTopNavV2 from "components/PageTopNavV2";
import {
	useEnvContext,
	useGoogleAnalyticsContext,
	useWebStoreContext
} from "contexts";
import { IShopReward, IShopRewardItem } from "contexts/WebStoreProvider/types";
import { FeaturesFlags, getCount, getDisplay } from "helpers";
import { PrivateRoute } from "hoc";
import { useAppDispatch } from "hooks";
import useBreakpoints from "hooks/useBreakpoints";
import useNewDay from "hooks/useNewDay";
import { Config, PurchaseOfferSingleItem, WsDialogs } from "interfaces";
import { DripOfferResponse, Node } from "interfaces/drip-offer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	featureTrackerAPI,
	useClaimDripOfferRewardMutation,
	useGetFeatureTrackerQuery,
	useGetOffersQuery
} from "redux/apis";
import { onOpenPopUp } from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { InfoIcon } from "utils/svgIcons";
import {
	getAvailablePrizes,
	getNodeItemData,
	handleImageVariant,
	handleTicketVariant,
	handleTitleImageVariant
} from "./hooks";
import {
	DisplayFirstNode,
	DisplayNode,
	DripOfferSkeleton,
	Offer,
	SaleInfo
} from "./lib";
import {
	Background,
	Button,
	DripOfferContainer,
	InfoButton,
	Title,
	timerStyle,
	timerStyleMobile
} from "./styles";

const DripOffer = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const {
		authToken,
		handleErrorResponse,
		nowTime,
		postUserMetadata,
		store,
		suppressOrderConfirmation,
		userTracker,
		wsStoreBuy,
		openLoginToContinueDialog
	} = useWebStoreContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const { openDialog } = useDialogModalRedux();
	const { isSmDown } = useBreakpoints();
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isSmaller = useMediaQuery("(max-width: 390px)");
	const isExtraSmall = useMediaQuery("(max-width: 360px)");

	const [ClaimDripOfferReward, { isLoading: IsClaimLoading }] =
		useClaimDripOfferRewardMutation();

	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);

	const { newDay } = useNewDay();
	const {
		data: dripOfferData,
		refetch: refetchDripOffer,
		isSuccess,
		isFetching,
		isError
	} = useGetFeatureTrackerQuery(
		{
			FeatureType: "drip-offer",
			isAuthenticated: !!userTracker
		},
		{
			skip: !isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER) || !userTracker,
			refetchOnFocus: true
		}
	);

	const { data: purchaseOffers } = useGetOffersQuery({
		offerType: "singleItem",
		isAuthenticated: authToken?.value ? true : false
	});

	const [firstOffer, setFirstOffer] = useState<{
		offer: PurchaseOfferSingleItem;
		items: IShopRewardItem[];
	}>();
	const [availableNode, setAvailableNode] = useState<Node>();

	const { tracker }: DripOfferResponse = dripOfferData || {};

	const handleEnd = () => {
		setTimeout(() => {
			refetchDripOffer();
		}, 1000);
	};

	const invalidateFeatureTracker = () => {
		dispatch(
			featureTrackerAPI.util.invalidateTags([
				{
					type: "featureTrackerTag",
					id: "drip-offer"
				}
			])
		);
	};

	const onClickBuy = async (offer?: PurchaseOfferSingleItem, node?: Node) => {
		if (!authToken?.value) {
			return;
		}

		if (tracker.offerPurchaseDate && node) {
			await ClaimDripOfferReward({
				eventId: node.eventId,
				nodeIndex: node.nodeIndex
			})
				.unwrap()
				.then(() => {
					if (getAvailablePrizes(tracker.nodes) === 1) {
						dispatch(onOpenPopUp("drip-offer-end"));
					} else {
						dispatch(onOpenPopUp("drip-offer-reward"));
					}
					refetchDripOffer();
					setAvailableNode(undefined);
					if (store) {
						const timeRemaining = getCount(nowTime, tracker.claimEndDate);
						const timeToDisplay = getDisplay(timeRemaining);
						const rewardType: IShopRewardItem[] | null = getNodeItemData(
							store,
							node
						);
						reportEvent("drip_offer", {
							source: "claim_free_daily_reward",
							reward_id: node.rewardId,
							reward_type: `${rewardType?.[0].id}_${rewardType?.[0].value}`,
							location: "special_offer_section",
							time_to_claim: timeToDisplay
						});
					}
				})
				.catch(() => {
					const response = {
						data: {
							errorCode: "The prize has already been claimed!",
							errorMessage: ""
						}
					};
					handleErrorResponse({ response: response });
					invalidateFeatureTracker();
				});
		} else if (!tracker.offerPurchaseDate && offer) {
			const { id, price, rewardId } = offer;

			wsStoreBuy({ offerId: id }).then((invoice) => {
				if (invoice) {
					openDialog(WsDialogs.XSOLLA_PURCHASE, {
						xsollaToken: invoice.xsollaToken,
						xsollaHost: invoice.xsollaHost,
						suppressOrderConfirmation,
						source: "drip-offer",
						postUserMetadata,
						onPurchaseStarted: (data) => {
							reportEvent("purchase_started", {
								section_name: "special_offer",
								offer_id: id,
								price_in_cents: price * 100,
								reward_id: rewardId,
								invoice_id: invoice?.id,
								paystation_id: data?.payStation
							});
						},
						onPurchaseSuccess: () => {
							reportEvent("purchase_success", {
								invoice_id: invoice?.id
							});
							refetchDripOffer();
							dispatch(onOpenPopUp("drip-offer-reward"));
						},
						cbClose: () => {
							reportEvent("dialog_x_closed", {
								source: "xsolla_dialog",
								page_location: location.pathname
							});
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		!userTracker && openLoginToContinueDialog();
	}, [userTracker]);

	useEffect(() => {
		if ((isSuccess && !dripOfferData?.tracker) || isError) {
			navigate("/");
		}
	}, [isSuccess, isError, dripOfferData]);

	useEffect(() => {
		if (newDay) {
			refetchDripOffer();
		}
	}, [newDay]);

	useEffect(() => {
		setAvailableNode(undefined);
	}, [isFetching]);

	useEffect(() => {
		if (tracker && purchaseOffers) {
			const offer: PurchaseOfferSingleItem = purchaseOffers.offers.find(
				(offer: Config) => offer.id === tracker.offerId
			);
			if (offer) {
				if (store) {
					const reward = store.rewards[offer.rewardId];
					if (!reward) {
						console.error("reward: ", reward);
						return;
					}
					const items: IShopRewardItem[] = Object.keys(reward.items).map(
						(key) => {
							const { imageUrl = "" } = {
								...store.rewardTypes.filter((t: IShopReward) => t.id === key)[0]
							};
							const rewardItem = reward.items;
							return {
								id: key,
								value: rewardItem[key as keyof typeof rewardItem],
								imageUrl
							};
						}
					);
					setFirstOffer({ offer: offer, items: items });
				}
			}
		}
	}, [purchaseOffers, tracker, store?.rewards]);

	if (tracker && Object.keys(tracker).length > 0 && firstOffer) {
		const {
			claimEndDate,
			dateTimes,
			isFinished,
			nodes,
			offerBackgroundImageUrl,
			offerEndDate,
			offerPurchaseDate,
			offerTicketImageUrl,
			saleInfoImageUrl,
			titleImageUrl
		} = tracker;
		return (
			<PrivateRoute
				isEnabled={
					!!userTracker && isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER)
				}
			>
				{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
					<PageTopNavV2 sectionMenuData={sectionMenuData} />
				) : (
					<PageTopNav sectionMenuData={sectionMenuData} />
				)}
				<DripOfferContainer
					largeTicket={handleImageVariant(
						offerPurchaseDate ? saleInfoImageUrl : offerTicketImageUrl,
						isPortrait && isSmDown
					)}
				>
					<Background
						background={handleImageVariant(
							offerBackgroundImageUrl,
							isPortrait && isSmDown
						)}
					/>
					<Container
						sx={{ px: { xs: isExtraSmall ? 3 : isSmaller ? 4.5 : 6, sm: 3 } }}
					>
						<Grid container>
							<Grid
								item
								xs={12}
								textAlign="center"
								zIndex={1}
								mb={6}
								position="relative"
							>
								<CountdownV2
									endTime={claimEndDate ?? offerEndDate}
									style={isPortrait ? timerStyleMobile : timerStyle}
									startTime={nowTime}
									text={claimEndDate ? "Time to claim: " : "Offer ends in: "}
									labelStyle={{ color: "#fff", marginLeft: 6 }}
									onEnd={() => {
										handleEnd();
									}}
								/>
								<Title
									src={handleTitleImageVariant(
										titleImageUrl,
										!!offerPurchaseDate
									)}
									alt="Tiki Travels"
								/>
								<InfoButton
									aria-label="open info popup"
									onClick={() => {
										dispatch(onOpenPopUp("drip-offer-info"));
									}}
								>
									<InfoIcon width={30} height={30} />
								</InfoButton>
							</Grid>
							{!offerPurchaseDate ? (
								<Offer firstOffer={firstOffer} />
							) : (
								<>
									{!isSmDown && (
										<SaleInfo
											nodes={nodes}
											localTime={dateTimes.local}
											nowTime={nowTime}
											endTime={claimEndDate}
										/>
									)}
								</>
							)}
							<Grid
								item
								sm={offerPurchaseDate ? 11 : 10}
								md={11}
								lg={12}
								zIndex={1}
								mx="auto"
							>
								<Grid
									container
									direction="row"
									spacing={1}
									mb={2}
									justifyContent="center"
								>
									{firstOffer && offerPurchaseDate && (
										<DisplayFirstNode
											items={firstOffer.items}
											background={handleTicketVariant(
												nodes[0].rewardBackgroundImageUrl,
												false
											)}
										/>
									)}
									{nodes.map((node) => (
										<DisplayNode
											key={node.rewardId}
											node={node}
											onSetAvailableNode={(n: Node) =>
												!isFetching && setAvailableNode(n)
											}
											changeSize={!!offerPurchaseDate}
											availableNode={availableNode}
										/>
									))}
									{offerPurchaseDate && isSmDown && (
										<SaleInfo
											nodes={nodes}
											localTime={dateTimes.local}
											nowTime={nowTime}
											endTime={claimEndDate}
										/>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12} mb={8} display="flex" justifyContent="center">
								{!isFinished && (
									<Button
										disabled={
											(offerPurchaseDate && !availableNode) ||
											(availableNode && availableNode.state !== "available") ||
											IsClaimLoading
										}
										onClick={() => onClickBuy(firstOffer?.offer, availableNode)}
									>
										{offerPurchaseDate
											? availableNode && availableNode?.state === "available"
												? "COLLECT"
												: "Come Back Tomorrow"
											: `${firstOffer?.offer.price} USD`}
									</Button>
								)}
							</Grid>
						</Grid>
					</Container>
				</DripOfferContainer>
			</PrivateRoute>
		);
	} else {
		return (
			<PrivateRoute
				isEnabled={
					!!userTracker && isFeatureFlagEnabled(FeaturesFlags.DRIP_OFFER)
				}
			>
				<DripOfferSkeleton />
			</PrivateRoute>
		);
	}
};

export default DripOffer;
