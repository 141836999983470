import { ReactComponent as CrownIcon } from "assets/Crown.svg";
import { Link as RouterLink } from "react-router-dom";
import CloseIconButton from "../lib/CloseIconButton";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	DialogActions
} from "@mui/material";
import { useGoogleAnalyticsContext, useEnvContext } from "contexts";
import ThemeButton from "components/Buttons/ThemeButton";
import { useAppDispatch } from "hooks";
import { onCloseDIP } from "redux/slices";

const DialogErrorInsufficientLoyaltyPointBalance = ({
	show,
	onCloseClick
}: {
	show: boolean;
	onCloseClick: () => void;
}) => {
	const dispatch = useAppDispatch();
	const { cdnPrefix } = useEnvContext();
	const { reportEvent } = useGoogleAnalyticsContext();
	const backgroundImage = `url(${cdnPrefix}/spec_dialogs/oops2.png)`;

	return (
		<Dialog
			open={show}
			onClose={onCloseClick}
			style={{ zIndex: 99999 }}
			sx={{
				".MuiPaper-root": {
					borderRadius: 6,
					paddingBottom: ".15rem",
					width: "462px"
				}
			}}
		>
			<DialogTitle
				sx={{
					height: "199px",
					padding: 0,
					backgroundSize: "cover",
					backgroundColor: "#3B2FC5",
					backgroundImage: backgroundImage,
					backgroundPosition: "center",
					overflow: "hidden"
				}}
			>
				<CloseIconButton
					onClose={() => {
						onCloseClick();
						reportEvent("dialog_x_close", {
							source: "insufficient_funds_dialog"
						});
					}}
				/>
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 0 }}>
				<DialogContentText sx={{ padding: ".75rem 1rem" }}>
					<Typography
						variant="h4"
						component="strong"
						sx={{
							fontWeight: "bold",
							margin: "1rem 0 .75rem",
							display: "block",
							textAlign: "center",
							lineHeight: 1,
							color: "#333333"
						}}
					>
						Insufficient funds
					</Typography>
					<Typography sx={{ margin: "8px 0" }}>
						You do not have enough{" "}
						<CrownIcon style={{ width: "16px", marginBottom: "-2px" }} />{" "}
						to purchase this item. Make a purchase In-Shop to earn more.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "center", flexDirection: "column" }}>
				<RouterLink
					to={"/shop"}
					onClick={() => {
						dispatch(onCloseDIP());
						reportEvent("earn_in_store_click", {
							source: "insufficient_funds_dialog"
						});
						onCloseClick();
					}}
					style={{
						width: "calc(100% - 3rem)",
						textDecoration: "none",
						margin: "0.8rem",
						marginBottom: 0
					}}
				>
					<ThemeButton
						sx={{
							paddingTop: ".75rem",
							paddingBottom: ".75rem",
							marginBottom: 1,
							width: "100%"
						}}
					>
						Earn <CrownIcon style={{ margin: "0 .5rem" }} /> in Shop
					</ThemeButton>
				</RouterLink>
				<ThemeButton
					onClick={() => {
						onCloseClick();
						reportEvent("continue_shopping_click", {
							source: "insufficient_funds_dialog"
						});
					}}
					sx={{
						paddingTop: ".75rem",
						paddingBottom: ".75rem",
						marginBottom: 1,
						width: "calc(100% - 3rem)",
						marginLeft: 2,
						marginRight: 2,
						backgroundColor: "#4088B7",
						"&:hover": {
							backgroundColor: "#25506d"
						}
					}}
				>
					Continue Shopping
				</ThemeButton>
			</DialogActions>
		</Dialog>
	);
};

export { DialogErrorInsufficientLoyaltyPointBalance };
