import { Grid, useMediaQuery } from "@mui/material";
import arrowUp from "assets/tutorial/icon_greenArrow.svg";
import backgroundPayment1 from "assets/tutorial/purchase-step01.png";
import backgroundPayment2 from "assets/tutorial/purchase-step02.png";
import tiki from "assets/tutorial/tiki-2.png";
import Button from "components/Buttons/ThemeButton";
import useCalculateScale from "components/PopUpManager/hooks/useCalculateScale";
import { useAppDispatch } from "hooks";
import { useState } from "react";
import { onClosePopUp } from "redux/slices";
import { Arrow, Background2, BackgroundPaimentLarge, ContainerScaledPayment, Text, Tiki2 } from "../../styles";

interface Slide4Props {
	onNext: () => void;
}

export const Slide4 = ({ onNext }: Slide4Props) => {
	const dispatch = useAppDispatch();
	const isExtraSmall = useMediaQuery("(max-width: 380px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");

	const [step, setStep] = useState<number>(1);

	const scale = useCalculateScale(
		isPortrait ? 380 : 185,
		isPortrait ? 185 : step === 1 ? 500 : 700
	);

	return (
		<>
			<ContainerScaledPayment scale={scale} step={step}>
				{step === 1
					? <BackgroundPaimentLarge
						id="slide-4-step-1-image"
						src={backgroundPayment1}
						alt="Payment tutorial image 1"
					/>
					: <BackgroundPaimentLarge
						id="slide-4-step-2-image"
						src={backgroundPayment2}
						alt="Payment tutorial image 2"
					/>
				}
				<Grid
					px={3}
					container
					width={380}
					position="absolute"
					left={isExtraSmall ? "-50%" : "-190px"}
					sx={{ transform: "translateX(50%)" }}
					bottom={0}
				>
					<Grid item xs={12} display="flex" justifyContent="flex-end" mr={step === 1 ? 7 : 9}>
						<Arrow src={arrowUp} alt="Arrow up" />
					</Grid>

					<Grid item xs={12} display="flex" justifyContent="right">
						<Text color="#fff" fontFamily="Baloo" fontSize="16px" lineHeight="17px">
							{step === 1 ? "5/7" : "6/7"}
						</Text>
					</Grid>

					<Grid item xs={12}>
						<Background2>
							<Grid container mb={3}>
								<Grid item xs={2.5}>
									<Tiki2 src={tiki} alt="Tiki" bottom={step === 1 ? "11px" : "13px"} />
								</Grid>
								<Grid item xs={9.5} mb={step === 1 ? 2 : 0}>
									<Text color="#462C12" fontFamily="Inter" fontSize="16px" fontWeight={700} lineHeight="17px">
										{step === 1
											? "Enter your payment details and save them for quicker future purchases."
											: "Confirm your purchase, and your items will appear instantly in your in-game inbox."
										}
									</Text>
								</Grid>
							</Grid>
							<Button className="next" onClick={() => step === 1 ? setStep(2) : onNext()}>Next</Button>
							<Button className="skip" variant="text" onClick={() => dispatch(onClosePopUp())}>Skip</Button>
						</Background2>
					</Grid>
				</Grid>
			</ContainerScaledPayment>
		</>
	)
};
