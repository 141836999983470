/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Container, Text } from "./styles";

interface BannerProps {
	firstPurchaseText: string | undefined;
	firstPurchaseRewardBonus: string | undefined;
}

export const FirstPurchaseBanner: React.FC<BannerProps> = ({
	firstPurchaseText,
	firstPurchaseRewardBonus
}) => {
	return (
		<Container>
			<Text>
				<span className="first-purchase-text">{firstPurchaseText}</span>
				<span className="first-purchase-bonus">{firstPurchaseRewardBonus}</span>
			</Text>
		</Container>
	);
};
