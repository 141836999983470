import { Grid, useMediaQuery } from "@mui/material";
import redBadge from "assets/buy-it-all/badge-buy-it-all-button.png";
import fingerPointerIcon from "assets/buy-it-all/finger-pointer-icon.png";
import classNames from "classnames";
import { getClassNameVariant } from "components/DIP/helper";
import { getFlexBasis } from "components/DIP/lib/helper";
import { useEnvContext, useGoogleAnalyticsContext, useWebStoreContext } from "contexts";
import { FeaturesFlags, sortRewardsWithSpecificIdAtEnd } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import { DipButton, WsDialogs } from "interfaces";
import { Fragment, useState } from "react";
import {
	getLimitedDeals,
	selectPoisBountyV2State,
	selectWebStoreState
} from "redux/slices";
import { useDialogModalRedux } from "redux/slices/dialog/hooks/useDialogModalRedux";
import { CardFinalMessage, LoyaltyPointsV2 } from "styles/general_styles";
import { getDisplayRewards } from "utils/getDisplayRewards";
import crownV2 from "assets/crownV2.png";
import { CrownIcon } from "utils/svgIcons";
import { IShopPurchaseOffer } from "contexts/WebStoreProvider/types";
import {
	BestValueBadge,
	Button,
	BuyItAllButton,
	BuyItAllFooterText,
	BuyItAllItem,
	BuyItAllStyled,
	FingerPointer,
	Footer,
	ItemImage,
	Items,
	LockedOffer,
	LoyaltyPoints,
	RewardAmount,
	RewardItem
} from "./styles";

interface BuyItAllProps {
	data: Omit<IShopPurchaseOffer, "onlyNonPayers" | "priority" | "purchaseButtonBannerText" | "bannerText">[];
}

export const BuyItAll: React.FC<BuyItAllProps> = ({ data }: BuyItAllProps) => {
	const dispatch = useAppDispatch();
	const isLowWidth = useMediaQuery("(max-width:733px)");
	const isMobile = isLowWidth;
	const { rewards, rewardTypes } = useAppSelector(selectWebStoreState).store;
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isFeatureFlagEnabled } = useEnvContext();
	const { openDialog } = useDialogModalRedux();
	const { wsStoreBuy, suppressOrderConfirmation, postUserMetadata } = useWebStoreContext();
	const { isEventActiveV2, remaining24hour } = useAppSelector(selectPoisBountyV2State);
	const { userTracker } = useWebStoreContext();
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	const [lockOffers, setLockOffers] = useState<number>(-1);
	const [disableButtons, setDisableButtons] = useState<boolean>(false);

	if (!data) {
		return <Fragment />;
	}

	const allOffersButLast = data.slice(0, -1);
	const lastItem = data.slice(-1)[0];

	const onBuyClick = (id: string, price: number, rewardId: string) => {
		setDisableButtons(true);
		wsStoreBuy({ offerId: id }).then((invoice) => {
			if (invoice) {
				openDialog(WsDialogs.XSOLLA_PURCHASE, {
					xsollaToken: invoice.xsollaToken,
					xsollaHost: invoice.xsollaHost,
					suppressOrderConfirmation,
					source: "dips",
					postUserMetadata,
					onPurchaseStarted: (data) => {
						reportEvent("purchase_started", {
							section_name: "limited_deals",
							offer_id: id,
							price_in_cents: price * 100,
							reward_id: rewardId,
							invoice_id: invoice?.id,
							paystation_id: data?.payStation
						});
					},
					onPurchaseSuccess: () => {
						reportEvent("purchase_success", {
							invoice_id: invoice?.id
						});
						dispatch(getLimitedDeals());
					},
					cbClose: () => {
						setDisableButtons(false);
						reportEvent("dialog_x_closed", {
							source: "xsolla_dialog",
							page_location: location.pathname
						});
					}
				});
			}
		});
	};

	const onOfferClick = (
		id: string,
		price: number,
		rewardId: string,
		index: number
	) => {
		if (lockOffers !== -1 && lockOffers < 3) {
			onBuyClick(id, price, rewardId);
		} else {
			setLockOffers(index);
		}
	};

	const onCancelLock = (index: number) => {
		setLockOffers(index);
	};

	const onBuyItAll = (id: string, price: number, rewardId: string) => {
		setLockOffers(3);
		onBuyClick(id, price, rewardId);
	};

	return (
		<BuyItAllStyled
			className={getClassNameVariant(data?.length as number)}
		>
			<Grid container spacing={1} justifyContent="center" alignItems="flex-end">
				{allOffersButLast.map(
					(
						{
							rewardId,
							price,
							loyaltyPointReward,
							id,
							badgeText,
							badgeImageUrl
						},
						i
					) => {
						const items = getDisplayRewards(rewardId, {
							rewards,
							rewardTypes
						});
						return (
							<Grid
								item
								key={id}
								xs={4}
								sm={3.5}
								md={3.75}
								lg={3.5}
								position="relative"
							>
								{lockOffers !== -1 && lockOffers !== i && (
									<div onClick={() => onCancelLock(i)}>
										<LockedOffer />
									</div>
								)}
								<BuyItAllItem
									tier={i}
									className={classNames([
										getClassNameVariant(data.length as number),
										loyaltyPointReward && "loyalty-reward",
										"item-reward"
									])}
								>
									{badgeText && badgeImageUrl && (
										<BestValueBadge
											className={`${lockOffers !== -1 && lockOffers !== i && "blocked-offer"}`}
											backgroundImage={badgeImageUrl}
										>
											<span>{badgeText}</span>
										</BestValueBadge>
									)}
									<Items
										tier={i}
										className={classNames([
											getClassNameVariant(data.length as number),
											"bundle-rewards-item"
										])}
									>
										{sortRewardsWithSpecificIdAtEnd(items, "random").map(
											({ imageUrl, description }, key) => {
												const flexBasis = getFlexBasis(
													data as unknown as DipButton[],
													items,
													isMobile
												);

												return (
													<RewardItem key={key} width={flexBasis + "%"}>
														<ItemImage src={imageUrl} alt="" />
														<RewardAmount className="description">
															{description}
														</RewardAmount>
													</RewardItem>
												);
											}
										)}
									</Items>

									<Footer
										className={classNames([
											loyaltyPointReward && "loyalty-reward",
											getClassNameVariant(data.length as number)
										])}
									>
										<Grid container>
											<Grid
												container
												spacing={{ xs: 0.5, sm: 0.5, md: 1 }}
												alignItems="center"
											>
												<Grid item xs={12} sm={isLowWidth ? 12 : 6} md={5}>
													{loyaltyPointReward && (
														<>
															{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
																<LoyaltyPointsV2 padding=".5rem .8rem">
																	<div>
																		Earn <img src={crownV2} width={15} />
																		{loyaltyPointReward?.toLocaleString("en-US")}
																	</div>
																</LoyaltyPointsV2>
															) : (
																<LoyaltyPoints>
																	<div>
																		Earn <CrownIcon width={isLowWidth ? "2.7vw" : "1.5vw"} />
																		{loyaltyPointReward?.toLocaleString("en-US")}
																	</div>
																</LoyaltyPoints>
															)}
														</>
													)}
												</Grid>
												<Grid item xs={12} sm={isLowWidth ? 12 : 6} md={7} position="relative">
													{lockOffers === i && (
														<FingerPointer
															src={fingerPointerIcon}
															alt="Press buy again to confirm"
														/>
													)}
													<Button
														disabled={disableButtons}
														onClick={() => onOfferClick(id, price, rewardId, i)}
													>
														{price} USD
													</Button>
												</Grid>
												{isPoisActive && (
													<Grid item xs={12}>
														<CardFinalMessage
															fontSize={isLowWidth ? "2.7vw" : "1.7vw"}
															color="#FBBD05"
															className="with-contrast"
														>
															Contribute to Poi’s Bounty
														</CardFinalMessage>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Footer>
								</BuyItAllItem>
							</Grid>
						);
					}
				)}
			</Grid>
			<Grid item position="relative">
				{lastItem.badgeText && lastItem.badgeImageUrl && (
					<BestValueBadge
						className={`buy-all-button ${lockOffers !== -1 && lockOffers !== 3 && "blocked-offer"}`}
						backgroundImage={redBadge}
					>
						<span>{lastItem.badgeText}</span>
					</BestValueBadge>
				)}
				<BuyItAllButton
					disabled={disableButtons}
					onClick={() => onBuyItAll(lastItem.id, lastItem.price, lastItem.rewardId)}
					themeColor="tertiary"
				>
					Buy All {lastItem.price} USD
				</BuyItAllButton>
			</Grid>
			<BuyItAllFooterText>Only one purchase can be made</BuyItAllFooterText>
		</BuyItAllStyled>
	);
};
