import { BrowserTracker, BuildType } from "@scopely/web-sdk-analytics";
import {
	LOGIN_REDIRECT_URL,
	LOGOUT_REDIRECT_URL,
	SCOPELY_ANALYTICS_API_KEY,
	SCOPELY_IDENTITY_API_ENDPOINT,
	SCOPELY_IDENTITY_CLIENT_ID,
	SCOPELY_IDENTITY_PROVIDER_URL
} from "./constants";
import { Settings as ScopelyAnalyticsSettings } from "@scopely/web-sdk-analytics/dist/src/settings";
import { OktaProviderConfig, ScopelyId } from "@scopely/web-sdk-scopelyid";
import { Config as ScopelyIdConfig } from "@scopely/web-sdk-scopelyid/dist/src/settings";
import { isMobile } from "react-device-detect";

import {
	DataSharingConsentService,
	CacheService,
	DataSharingApiService
} from "@scopely/web-sdk-privacy-settings";
import { BrowserStorage } from "@scopely/web-sdk-core";
import {IUserTracker} from "../WebStoreProvider/types";

let buildType = BuildType.Production;
if (process.env.REACT_APP_WITH_DEBUG === "true") {
	buildType = BuildType.Debug;
}

const analyticsSettings = new ScopelyAnalyticsSettings(
	SCOPELY_ANALYTICS_API_KEY,
	buildType
);

export const analyticsTracker = new BrowserTracker(analyticsSettings);
let loginType = "";
try {
	const user = localStorage.getItem("store.solitairetripeaks.com:userTracker");
	const userTracker: IUserTracker = user ? JSON.parse(user) : null;
	loginType = userTracker?.metadata?.userData?.loginType ?? "";
} catch (error) {
	console.log(error);
}
analyticsTracker.updateAppProperties({
	"sys.app_version": process.env.REACT_APP_WEBSTORE_RELEASE_VERSION,
	"sys.store": process.env.REACT_APP_PAYMENT_PROVIDER,
	"sys.user_id": analyticsTracker.getContext().getTenant(),
	"tp.device_type": isMobile ? "mobile" : "desktop",
	"tp.login_method": loginType
});
analyticsTracker.register();

export const dataSharingService = new DataSharingConsentService(
	analyticsTracker,
	new CacheService(new BrowserStorage("privacy")),
	new DataSharingApiService(SCOPELY_ANALYTICS_API_KEY)
);

const providerConfig = new OktaProviderConfig(
	SCOPELY_IDENTITY_PROVIDER_URL,
	SCOPELY_IDENTITY_CLIENT_ID,
	LOGIN_REDIRECT_URL,
	LOGOUT_REDIRECT_URL
);
const scopelyIdConfig = new ScopelyIdConfig(
	providerConfig,
	SCOPELY_IDENTITY_API_ENDPOINT
);
export const scopelyId = new ScopelyId(scopelyIdConfig, analyticsTracker);
