import styled from "styled-components";
import FirstPurchaseBadgeIcon from "assets/red-badge.svg";

export const Container = styled("div")`
	position: absolute;
	z-index: 1;
	top: -23px;
	right: -5px;
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${FirstPurchaseBadgeIcon});
	background-size: cover;
	//transform: rotate(-21deg);
	color: #fff;
	font-family: "Baloo";
	font-size: 11px;
	line-height: 12px;
	text-shadow: 0px 0px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 0px 1px 0 #000, 0px -1px 0 #000, -1px 0px 0 #000,
		1px 0px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000, 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000,
		-1px -1px 0 #000;

	animation: scalerotate 2s infinite;

	@keyframes scalerotate {
		0% {
			transform: scale(1) rotate(-30deg);
		}
		50% {
			transform: scale(1.2) rotate(-20deg);
		}
		100% {
			transform: scale(1) rotate(-30deg);
		}
	}
`;

export const Text = styled.span`
	margin-bottom: 5px;
	position: relative;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		animation: fadeInText 4s infinite;
	}

	span.first-purchase-text {
	}

	span.first-purchase-bonus {
		font-size: 15px;
		animation-delay: 2s;
	}

	@keyframes fadeInText {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 0;
		}
		75% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;
