import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import redBadge from "assets/buy-it-all/badge-buy-it-all-button.png";
import fingerPointerIcon from "assets/buy-it-all/finger-pointer-icon.png";
import crownV2 from "assets/crownV2.png";
import classNames from "classnames";
import Button from "components/Buttons/ThemeButton";
import { getClassNameVariant } from "components/DIP/helper";
import { useDIPAction, useDIPRewards } from "components/DIP/hooks";
import { useEnvContext, useWebStoreContext } from "contexts";
import { FeaturesFlags, sortRewardsWithSpecificIdAtEnd } from "helpers";
import { useAppSelector } from "hooks";
import { DipButton } from "interfaces";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { selectDipState, selectPoisBountyV2State } from "redux/slices";
import { RootState } from "redux/store";
import { CardFinalMessage, LoyaltyPointsV2 } from "styles/general_styles";
import { CrownIcon } from "utils/svgIcons";
import { getFlexBasis } from "../helper";
import {
	BestValueBadge,
	BuyItAllButton,
	BuyItAllFooterText,
	BuyItAllItem,
	BuyItAllStyled,
	FingerPointer,
	Footer,
	ItemImage,
	Items,
	LockedOffer,
	LoyaltyPoints,
	RewardAmount,
	RewardItem
} from "./styles";

interface BuyItAllProps { }

export const BuyItAll = ({ }: BuyItAllProps) => {
	const { currentDip, claimRewardProcess } = useAppSelector(selectDipState);
	const { isEventActiveV2, remaining24hour } = useAppSelector(
		selectPoisBountyV2State
	);
	const showOfferId = useSelector(
		(state: RootState) => state.debug.showDebugLabels
	);
	const { isFeatureFlagEnabled } = useEnvContext();
	const { userTracker } = useWebStoreContext();
	const { getDIPReward } = useDIPRewards();
	const { onAction } = useDIPAction();
	const { loading, claiming } = claimRewardProcess;
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const isMobile = isPortrait;
	const isPoisActive = isEventActiveV2 && !remaining24hour && !!userTracker;
	const [lockOffers, setLockOffers] = useState<number>(-1);

	if (typeof currentDip === "undefined") {
		return <Fragment />;
	}
	const { buttons: data } = currentDip;
	if (!data) {
		return <Fragment />;
	}
	const allOffersButLast = data.slice(0, -1);
	const lastItem = data.slice(-1)[0];
	const { price, buyType, badgeText, badgeImageUrl } = getDIPReward(lastItem);

	const onOfferClick = (item: DipButton, index: number) => {
		if (lockOffers !== -1 && lockOffers < 3) {
			onAction(item);
		} else {
			setLockOffers(index);
		}
	};

	const onCancelLock = (index: number) => {
		setLockOffers(index);
	};

	const onBuyItAll = (item: DipButton) => {
		onAction(item);
		setLockOffers(3);
	};

	return (
		<BuyItAllStyled
			className={getClassNameVariant(allOffersButLast.length as number)}
		>
			<Grid container spacing={1} justifyContent="center" alignItems="flex-end">
				{allOffersButLast.map((item, i) => {
					const {
						price,
						buyType,
						buttonLabel,
						rewards,
						loyaltyPointReward,
						badgeText,
						badgeImageUrl
					} = getDIPReward(item);
					return (
						<Grid item key={item.id} xs={4} position="relative">
							{lockOffers !== -1 && lockOffers !== i && (
								<div onClick={() => onCancelLock(i)}>
									<LockedOffer />
								</div>
							)}
							<BuyItAllItem
								tier={i}
								className={classNames([
									getClassNameVariant(allOffersButLast.length as number),
									loyaltyPointReward && "loyalty-reward",
									"item-reward"
								])}
							>
								{badgeText && badgeImageUrl && (
									<BestValueBadge
										className={`${
											lockOffers !== -1 && lockOffers !== i && "blocked-offer"
										}`}
										backgroundImage={badgeImageUrl}
									>
										<span>{badgeText}</span>
									</BestValueBadge>
								)}
								{showOfferId && (
									<Typography
										sx={{
											fontWeight: 700,
											color: "red",
											fontSize: "12px",
											height: "auto",
											textAlign: "center"
										}}
									>
										{item.loyaltyOfferId ? item.loyaltyOfferId : item.offerId}
									</Typography>
								)}
								<Items
									tier={i}
									className={classNames([
										getClassNameVariant(allOffersButLast.length as number),
										"bundle-rewards-item"
									])}
								>
									{sortRewardsWithSpecificIdAtEnd(rewards, "random").map(
										({ imageUrl, description }, key) => {
											const flexBasis = getFlexBasis(
												allOffersButLast,
												rewards,
												isMobile
											);

											return (
												<RewardItem key={key} width={flexBasis + "%"}>
													<ItemImage src={imageUrl} alt="" />
													<RewardAmount className="description">
														{description}
													</RewardAmount>
												</RewardItem>
											);
										}
									)}
								</Items>

								<Footer
									className={classNames([
										loyaltyPointReward && "loyalty-reward",
										getClassNameVariant(allOffersButLast.length as number)
									])}
								>
									<Grid container>
										<Grid
											container
											spacing={isMobile ? 0 : 1}
											alignItems="center"
										>
											<Grid item xs={isMobile ? 12 : 5}>
												{loyaltyPointReward && (
													<>
														{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
															<LoyaltyPointsV2 padding=".5rem .8rem">
																<div>
																	Earn <img className="crown-icon" src={crownV2} width={15} />
																	{loyaltyPointReward?.toLocaleString("en-US")}
																</div>
															</LoyaltyPointsV2>
														) : (
															<LoyaltyPoints>
																<div>
																	Earn <CrownIcon width={18} />
																	{loyaltyPointReward?.toLocaleString("en-US")}
																</div>
															</LoyaltyPoints>
														)}
													</>
												)}
											</Grid>
											<Grid item xs={isMobile ? 12 : 7} position="relative">
												{lockOffers === i && (
													<FingerPointer
														src={fingerPointerIcon}
														alt="Press buy again to confirm"
													/>
												)}
												<Button
													disabled={loading || claiming}
													onClick={() => onOfferClick(item, i)}
												>
													{!buttonLabel ? (
														<Fragment>
															{buyType === "loyalty_points" && (
																<CrownIcon width={16} />
															)}
															{price}
															{buyType === "usd" && " USD"}
														</Fragment>
													) : (
														buttonLabel
													)}
												</Button>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											{isPoisActive && (
												<CardFinalMessage
													color="#FBBD05"
													className="mt with-contrast"
												>
													Contribute to Poi’s Bounty
												</CardFinalMessage>
											)}
										</Grid>
									</Grid>
								</Footer>
							</BuyItAllItem>
						</Grid>
					);
				})}
			</Grid>
			<Grid item position="relative">
				{badgeText && badgeImageUrl && (
					<BestValueBadge
						className={`buy-all-button ${
							lockOffers !== -1 && lockOffers !== 3 && "blocked-offer"
						}`}
						backgroundImage={redBadge}
					>
						<span>{badgeText}</span>
					</BestValueBadge>
				)}
				<BuyItAllButton
					disabled={loading || claiming}
					onClick={() => onBuyItAll(lastItem)}
					themeColor="tertiary"
				>
					Buy All {price}
					{buyType === "usd" && " USD"}
				</BuyItAllButton>
			</Grid>
			<BuyItAllFooterText>Only one purchase can be made</BuyItAllFooterText>
		</BuyItAllStyled>
	);
};
