import { useEffect, useState } from "react";
import { useWebStoreContext } from "../../contexts/WebStoreProvider";
import { PrivateRoute } from "../../hoc";
import { useEnvContext } from "contexts/EnvProvider";
import { FeaturesFlags } from "helpers";
import { FreeGiftSection } from "./FreeGiftSection";
import { PageContent } from "components/PageContent";
import PageTopNav, { ISectionMenuItem } from "../../components/PageTopNav";
import PageTopNavV2 from "../../components/PageTopNavV2";

const ClubFreeGift = () => {
	const [sectionMenuData] = useState<ISectionMenuItem[]>([]);
	const { isFeatureFlagEnabled } = useEnvContext();
	const { userTracker } = useWebStoreContext();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<PrivateRoute
			isEnabled={
				(userTracker?.clubId ? true : false) &&
				isFeatureFlagEnabled(FeaturesFlags.CLUB_SHOP_FREE_GIFT)
			}
		>
			<>
				{isFeatureFlagEnabled(FeaturesFlags.UI_V2) ? (
					<PageTopNavV2 sectionMenuData={sectionMenuData} />
				) : (
					<PageTopNav sectionMenuData={sectionMenuData} />
				)}
				<PageContent source="club_free_gift_page">
					<FreeGiftSection />
				</PageContent>
			</>
		</PrivateRoute>
	);
};

export default ClubFreeGift;
