// import { Typography } from "@mui/material";
import styled from "styled-components";
import Button from "components/Buttons/ThemeButton";

export const OffersStyled = styled.div`
	display: flex;
	justify-content: center;
	padding: 7px;

	&.bouncy-chest {
		flex-direction: column;
	}

	@media (orientation: portrait) {
		width: 100%;
		height: 640px;
		align-items: center;
		flex-direction: column;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			width: 0px;
		}

		&.bouncy-chest {
			height: 500px;
		}
	}

	@media (orientation: landscape) {
		height: 255px;
		align-items: center;
		flex-wrap: nowrap;

		&.bouncy-chest {
			position: absolute;
			//height: unset;
			min-height: 323px;
			bottom: 50px;
			right: 95px;
		}
	}
`;

export const OfferItem = styled.div`
	//display: grid;
	grid-template-rows: auto;
	background-color: rgba(51, 51, 51, 0.3);
	border: 1px solid #9aeffc;
	border-radius: 12px;
	padding: 0.5rem;
	gap: 0.5rem;
	margin: 3px;
	width: 200px;
	height: auto;
	z-index: 999;
	align-content: stretch;

	@media (orientation: portrait) {
		width: 90%;
		&.one-item {
			min-width: unset;
			img {
				width: 55px;
				height: 55px;
			}
			&.bouncy-chest {
				img {
					width: 85px;
					height: 85px;
				}
			}
		}
		&.two-items {
			min-width: 250px;
		}
		&.several-items {
			min-width: 200px;
		}
	}
	@media (orientation: landscape) {
		gap: 1.2rem;
		img {
			width: 65px;
			height: 65px;
		}
		&.one-item {
			min-width: 400px;
			width: auto;

			&.bouncy-chest {
				width: 400px;
				img {
					width: 75px;
					height: 75px;
				}
			}
		}
		&.two-items {
			min-width: 350px;
		}
		&.several-items {
			min-width: 300px;
		}
	}

	img {
		&.crown-icon {
			width: 15px;
			height: 15px;
		}
	}
`;

export const Items = styled.div`
	justify-content: center;
	align-items: end;
	display: flex;
	flex-wrap: wrap;
`;
/* eslint-disable */
export const RewardItem = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: ${(props) => props.width};
	box-sizing: border-box;
	padding: 1px;
	//gap: 0.4rem;

	&.bouncy-chest {
		position: relative;
		padding: 6px;
	}
`;
/* eslint-enable */

export const RewardAmountContainer = styled.div`
	&.bouncy-chest {
		position: absolute;
		min-width: 42%;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: -10px;
	}
`;

export const RewardAmount = styled.div`
	color: #fff;
	font-weight: 700;
	font-size: 15px;

	&.bouncy-chest {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: right;
		font-family: "Baloo";
		font-weight: 300;
		font-size: 28px;
		text-shadow: 0 0 3px #000000, 0 0 4px #000000, 0 0 4px #000000,
			0 0 3px #000000;
	}

	@media (orientation: landscape) {
		&.bouncy-chest {
			font-size: 26px;
		}
	}
`;

export const ItemImage = styled.img`
	width: 46px;
	height: 46px;
	object-fit: contain;
	justify-self: center;
	align-self: center;
`;

export const Footer = styled.div`
	display: grid;
	align-items: center;
	button {
		padding: 0.5rem 0.2rem;
		gap: 0.4rem;
		//font-size: 0.9rem;
	}
	&.loyalty-reward {
		grid-template-columns: minmax(85px, auto) auto;
		grid-gap: 10px;

		&.bouncy-chest {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			grid-gap: 0px;
			width: 100%;
		}

		@media (orientation: portrait) {
			&.one-item {
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
				width: 100%;
				button {
					width: 100%;
				}

				&.bouncy-chest {
					flex-direction: column;
				}
			}
		}
	}
`;

export const LoyaltyPoints = styled.div`
	background-color: rgba(0, 0, 0, 0.51);
	color: #fff;
	font-size: 0.9rem;
	padding: 0.5rem 0.8rem;
	margin: 0.3rem 0rem;
	border-radius: 100px;

	> div {
		align-items: center;
		justify-content: center;
		display: flex;
		gap: 0.2rem;
	}
`;

export const OfferText = styled.div`
	font-family: "Inter", sans-serif;
	font-weight: 500;
	font-size: 14px;
	color: #ffffffff;
	line-height: 18px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (orientation: landscape) {
		font-size: 12px;
	}
`;

export const BouncyChestButton = styled(Button)`
	margin-top: 10px;
	width: 93%;
	font-size: 22px;
	height: 58px;

	@media (orientation: landscape) {
		width: 70%;
		font-size: 20px;
		height: 54px;
	}
`;
