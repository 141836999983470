import { FeaturesFlags } from "helpers";
import { useEnvContext } from "contexts";
import { Collapse, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useBreakpoints from "../../hooks/useBreakpoints";
import theme from "../../theme";
import Button from "../Buttons/ThemeButton";
import { useGoogleAnalyticsContext } from "../../contexts/GoogleAnalyticsProvider";

const gridColumnsConfig = { xs: 2, sm: 3, md: 4, lg: 4, xl: 5 };

export const Content = styled("div")`
	/* --grid-columns: 2; */
	--grid-columns: ${gridColumnsConfig.xs};

	transition: all 0.3s linear;
	display: grid;
	justify-content: space-between;
	gap: 1rem;
	grid-template-columns: repeat(
		var(--grid-columns),
		calc(96% / var(--grid-columns)) [col]
	);

	${theme.breakpoints.up("sm")} {
		/* --grid-columns: 3; */
		--grid-columns: ${gridColumnsConfig.sm};
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("md")} {
		/* --grid-columns: 4; */
		--grid-columns: ${gridColumnsConfig.md};
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("lg")} {
		/* --grid-columns: 4; */
		--grid-columns: ${gridColumnsConfig.lg};
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
	${theme.breakpoints.up("xl")} {
		/* --grid-columns: 5; */
		--grid-columns: ${gridColumnsConfig.xl};
		grid-template-columns: repeat(
			var(--grid-columns),
			calc(93% / var(--grid-columns)) [col]
		);
	}
`;

const SectionContent = ({
	items,
	isHideShowLess = false,
	sectionName,
	sectionPage
}: {
	items: JSX.Element[];
	isHideShowLess?: boolean;
	sectionName: string;
	sectionPage: string;
}) => {
	const { isSmUp, isMdUp, isLgUp, isXlUp } = useBreakpoints();
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [isShowMoreEnabled, setIsShowMoreEnabled] = useState<boolean>(false);
	const [collapsedSize, setCollapsedSize] = useState<number>(266); // approx row height
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const { reportEvent } = useGoogleAnalyticsContext();
	const { isFeatureFlagEnabled } = useEnvContext();

	const handleShowMore = () => {
		setIsShowMoreEnabled(!isShowMoreEnabled);
		reportEvent("see_more_click", {
			source: sectionPage + "_page",
			see_more_type: sectionName.toLowerCase()
		});
	};

	useEffect(() => {
		let gridColumns = gridColumnsConfig.xs;
		switch (true) {
			case isSmUp:
				gridColumns = gridColumnsConfig.sm;
				break;
			case isMdUp:
				gridColumns = gridColumnsConfig.md;
				break;
			case isLgUp:
				gridColumns = gridColumnsConfig.lg;
				break;
			case isXlUp:
				gridColumns = gridColumnsConfig.xl;
				break;
		}
		const contentHeight = contentRef?.current?.clientHeight || 0;
		const itemHeights = items.map((item) => {
			return item.props.itemRef?.current.clientHeight || 0;
		});
		// only check the height of the first row
		const maxItemHeight = Math.max(...itemHeights.splice(0, gridColumns));
		if (contentHeight > maxItemHeight) {
			setCollapsedSize(
				isFeatureFlagEnabled(FeaturesFlags.UI_V2) && gridColumns === 2
					? maxItemHeight * 2 + 16
					: maxItemHeight
			);
		} else {
			setIsDisabled(true);
		}
	}, [isSmUp, isMdUp, isLgUp, isXlUp, items]);

	useEffect(() => {
		setIsShowMoreEnabled(false);
	}, [isSmUp, isMdUp, isLgUp, isXlUp]);

	return (
		<>
			<Collapse
				in={isDisabled || isShowMoreEnabled}
				collapsedSize={collapsedSize}
				sx={{ paddingBottom: "3px" }}
			>
				<Content ref={contentRef}>{items}</Content>
			</Collapse>
			{!isDisabled && (
				<Button
					onClick={handleShowMore}
					sx={{
						color: "#4088B7",
						padding: 1,
						width: "100%",
						maxWidth: "450px",
						margin: "0 auto",
						marginTop: 2,
						opacity: isHideShowLess && isShowMoreEnabled ? "0" : "1",
						pointerEvents: isHideShowLess && isShowMoreEnabled ? "none" : "auto"
					}}
					variant="outlined"
				>
					SEE MORE
				</Button>
			)}
		</>
	);
};

export default SectionContent;
