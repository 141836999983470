import { styled } from "@mui/material";
import background from "assets/tutorial/background.svg";
import {
	backAndForth,
	shine,
	sideToSideLeft,
	sideToSideRight
} from "./animations";

export const ContainerScaled = styled("div")<{ scale: number }>`
	transform: scale(${(props) => props?.scale});
`;

export const ContainerScaledPayment = styled("div")<{
	scale: number;
	step: number;
}>`
	position: relative;
	width: 380px;
	height: ${(props) => (props.step === 1 ? "417px" : "572px")};
	transform: scale(${(props) => props?.scale});
`;

export const Tiki1 = styled("img")`
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	transform: rotateY(180deg);
`;

export const Tiki2 = styled("img")<{ bottom?: string }>`
	position: absolute;
	width: 32%;
	bottom: ${(props) => props.bottom || "8px"};
	left: -25px;
`;

export const Background1 = styled("div")`
	position: relative;
	background-color: #fffaf1;
	border-radius: 6px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	> svg {
		position: absolute;
		left: -7px;
		bottom: 18px;
	}
`;

export const Background2 = styled("div")`
	position: relative;
	padding: 15px;
	background-image: url(${background});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	.next {
		position: absolute;
		left: 50%;
		bottom: 5%;
		transform: translate(-50%, 50%);
		width: 112px;
	}
	.skip {
		position: absolute;
		right: 0;
		bottom: -30%;
	}
`;

export const BackgroundPaimentLarge = styled("img")`
	position: absolute;
	top: -25px;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
`;

export const BackgroundSingleItem = styled("img")`
	position: relative;
	max-width: 156px;
	z-index: -1;
	filter: grayscale(1);
`;

export const Text = styled("p")<{
	fontFamily: "Baloo" | "Inter";
	fontSize: "16px" | "12px";
	fontWeight?: 700;
	lineHeight: "17px" | "15px";
}>`
	margin: 0;
	color: ${(props) => props.color || "#333"};
	font-size: ${(props) => props.fontSize};
	font-family: ${(props) => props.fontFamily};
	font-weight: ${(props) => props.fontWeight || 400};
	line-height: ${(props) => props.lineHeight};
`;

export const Arrow = styled("img")`
	animation: ${backAndForth} 1s ease-in-out infinite;

	&.side-to-side-left {
		@media (orientation: landscape) {
			@media (max-height: 600px) {
				animation: ${sideToSideLeft} 1s ease-in-out infinite;
			}
		}
	}
	&.side-to-side-right {
		@media (orientation: landscape) {
			@media (max-height: 600px) {
				animation: ${sideToSideRight} 1s ease-in-out infinite;
			}
		}
	}
`;

export const Shine = styled("img")`
	display: none;

	&.animate {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0.8;
		animation: ${shine} 20s ease-in-out infinite;
		z-index: -1;
	}
`;
